// Ingredient Model
import { Model, Repository, useRepo } from 'pinia-orm'
import { NumberCast, BooleanCast } from 'pinia-orm/casts'
import { Composition } from '@/models/Composition'
import { Globalconfig } from '@/models/Globalconfig'
import axios from 'axios'

class Ingredient extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'ingredients'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(0),
      uid: this.string(''),
      name: this.string(''),
      image_url: this.string(''),
      aliases: this.attr(null),
      type: this.string(''),
      season_months: this.attr(null),
      need_bag: this.attr(false),
      need_box: this.attr(false),
      default_amount: this.attr(1),
      default_unit: this.string('nb'),
      unit_mass: this.attr(0),
      prefer_organic: this.attr(false),
      prefer_bulk: this.attr(false),
      basic: this.attr(false),
      composition: this.hasMany(Composition, 'ingredient_id')
    }
  }

  static casts() {
    return {
      id: NumberCast,
      need_bag: BooleanCast,
      need_box: BooleanCast,
      default_amount: NumberCast,
      unit_mass: NumberCast,
      prefer_organic: BooleanCast,
      prefer_bulk: BooleanCast,
      basic: BooleanCast
    }
  }

  get default_unit_shorthand() {
    const units = Ingredient.units()
    if (units && units.length) {
      const match = units.filter((a) => a.value === this.default_unit)
      if (match.length === 1) {
        return match[0].shorthand
      }
    }

    return this.default_unit
  }

  get image() {
    return this.image_url
  }

  getDataForQRCode() {
    if (this.uid) {
      return 'ingredient_u_' + this.uid
    }
    else {
      return 'ingredient_' + this.id
    }
  }

  seasonnality() {
    let months = parseInt('111111111111', 2)
    if (this.season_months && this.season_months.length > 0) {
      // Months are in the right order, starting at 1
      const ingMonths = this.season_months.reduce((prev, curr) => {
        if (prev === '') {
          // Bootstrap with a string of 0
          prev = (new Array(curr - 1)).fill('0').join('')
          return prev + '1'
        }
        else {
          // Need to insert a filler in case there is a gap between months
          let filler = ''
          if (curr > prev.length) {
            filler = (new Array(curr - prev.length - 1)).fill('0').join('')
          }
          return prev + filler + '1'
        }
      }, '')
      // Bitwise AND to continue building the mask
      months &= parseInt(ingMonths, 2)
    }

    // Apply the mask
    const strMonths = (months >>> 0).toString(2)

    const finalMonths = (new Array(12 - strMonths.length)).fill('0').concat(strMonths.split(''))
    return finalMonths
  }

  static allTypes() {
    const types = [
      { text: 'Légume', pluralText: 'Légumes', value: 'vegetable' },
      { text: 'Fruit', pluralText: 'Fruits', value: 'fruit' },
      { text: 'Viande', pluralText: 'Viandes', value: 'meat' },
      { text: 'Poisson', pluralText: 'Poissons', value: 'fish' },
      { text: 'Produit laitier', pluralText: 'Produits laitiers', value: 'dairy' },
      { text: 'Epice', pluralText: 'Epices', value: 'spice' },
      { text: 'Boisson', pluralText: 'Boissons', value: 'drink' }
    ]
    types.sort((a, b) => a.text < b.text ? -1 : 1)
    return types
  }

  static seasonTypes() {
    return ['vegetable', 'fruit', 'fish']
  }

  static units() {
    const units = useRepo(Globalconfig).find('ingredientUnits')
    return units ? units.value : []
  }

  static formatQuantity(amount, unit_label) {
    let str = '';
    if (amount) {
      str += amount
      if (unit_label) {
        str += ' ' + unit_label
      }
    }
    return str
  }

  static mutators() {
    return {
      season_months(value) {
        if (typeof value === 'string') {
          try {
            return JSON.parse(value)
          }
          catch (e) {
            return []
          }
        }
        else {
          if (Array.isArray(value)) {
            return value
          }
          else {
            return []
          }
        }
      },
      aliases(value) {
        if (typeof value === 'string') {
          try {
            return JSON.parse(value)
          }
          catch (e) {
            return []
          }
        }
        else {
          if (Array.isArray(value)) {
            return value
          }
          else {
            return []
          }
        }
      }
    }
  }

  static findByKey(key) {
    if (typeof key === 'number') {
      return this.find(key)
    }
    else {
      const ingList = this.where('uid', key).get()
      if (ingList.length === 1) {
        return ingList[0]
      }
    }

    return null
  }

  static getIdFromQRCode(data) {
    if (data && typeof data === 'string' && data.length > 0) {
      const regex = /^ingredient_([1-9][0-9]*)$/
      const matches = data.match(regex)
      if (matches && matches.length === 2) {
        return parseInt(matches[1])
      }
      else {
        const regex = /^ingredient_u_([a-z0-9]+)$/
        const matches = data.match(regex)
        if (matches && matches.length === 2) {
          return matches[1]
        }
      }

      return null
    }
    return null
  }
}

class IngredientRepo extends Repository {
  use = Ingredient

  needUpdate() {
    const lastUpdate = useRepo(Globalconfig).find('lastUpdate_ingredients')
    if (lastUpdate && lastUpdate.value) {
      // TODO: check date diff?
      return false
    }

    return true
  }
  setUpdate() {
    useRepo(Globalconfig).save({
      name: 'lastUpdate_ingredients',
      value: Date.now()
    })
  }
  needReload() {
    useRepo(Globalconfig).save({
      name: 'lastUpdate_ingredients',
      value: null
    })
  }

  async $fetch() {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.needUpdate()) {
          const ings = await axios.get('/ingredient/get.php')
          this.setUpdate()
          resolve(this.save(ings.data))
        }
        resolve()
      }
      catch (e) {
        reject()
      }
    })
  }

  async $fetchForGrocery(listId) {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.needUpdate()) {
          let uri = '/ingredient/get.php?filter=hasGrocery'
          if (listId) {
            uri += '&listId=' + encodeURIComponent(listId)
          }
          const ings = await axios.get(uri)
          resolve(this.save(ings.data))
        }
        resolve()
      }
      catch (e) {
        reject()
      }
    })
  }

  async $get(id) {
    return new Promise(async (resolve, reject) => {
      try {
        const ing = await axios.get('/ingredient/get.php?id=' + id)
        resolve(this.save(ing.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $getByRecipe(recipe_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const ings = await axios.get('/ingredient/get.php?recipe_id=' + recipe_id)
        resolve(this.save(ings.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $create(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const ing = await axios.post('/ingredient/post.php', data)
        resolve(this.save(ing.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $update(id, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const ing = await axios.put('/ingredient/put.php?id=' + id, data)
        resolve(this.save(ing.data))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $delete(id) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.delete('/ingredient/delete.php?id=' + id)
        resolve(this.destroy(id))
      }
      catch (e) {
        reject()
      }
    })
  }

  async $search(value) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get('/ingredient/search/get.php?value=' + value)
        if (response && response.data && response.data.matches) {
          this.save(response.data.matches)
        }
        resolve(response)
      }
      catch (e) {
        reject()
      }
    })
  }

  async $fillBasics() {
    return axios.post('/ingredient/fillBasics/post.php')
  }

  async $updateBasics() {
    return axios.post('/ingredient/updateBasics/post.php')
  }

  async $parse(rawIngredients) {
    return axios.post('/ingredient/parse/post.php', {
      value: rawIngredients
    })
  }

  async $loadUnits() {
    return new Promise(async (resolve, reject) => {
      try {
        const units = await axios.get('/ingredient/units/get.php')
        useRepo(Globalconfig).save({
          name: 'ingredientUnits',
          value: units.data
        })
        resolve()
      }
      catch (e) {
        reject()
      }
    })
  }
}

export {
  Ingredient,
  IngredientRepo
}