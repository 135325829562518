<template>
  <div
    class="enveloppe"
    :style="cssVar"
  >
    <p
      v-if="name"
      class="name"
    >
      {{ name }}
    </p>
    <div class="scoreContainer">
      <div :class="'note a ' + (innerVal === 'a' ? 'selected' : '')">
        A
      </div>
      <div :class="'note b ' + (innerVal === 'b' ? 'selected' : '')">
        B
      </div>
      <div :class="'note c ' + (innerVal === 'c' ? 'selected' : '')">
        C
      </div>
      <div :class="'note d ' + (innerVal === 'd' ? 'selected' : '')">
        D
      </div>
      <div :class="'note e ' + (innerVal === 'e' ? 'selected' : '')">
        E
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NutriScore',
  components: {
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      default: 'a',
    },
    name: {
      type: String,
      default: 'Nutri-score',
    },
    width: {
      type: Number,
      default: 100,
    },
  },
  data: () => ({
    innerVal: 'a',
    intervalID: 0,
    loadingUp: true,
    options: ['a', 'b', 'c', 'd', 'e']
  }),
  computed: {
    cssVar() {
      return {
        '--width': this.width
      }
    }
  },
  watch: {
    loading(newVal, oldVal) {
      if (newVal) {
        this.startLoad()
      }
      else {
        this.stopLoad()
      }
    },
    modelValue(newVal, oldVal) {
      if (!this.loading) {
        this.innerVal = newVal
      }
    }
  },
  created() {
    this.innerVal = this.modelValue
    if (this.loading) {
      this.startLoad()
    }
  },
  methods: {
    nextVal() {
      if (this.loadingUp) {
        if (this.options.indexOf(this.innerVal) === this.options.length - 2) {
          this.loadingUp = false
        }
        return this.options[(this.options.indexOf(this.innerVal) + 1) % 5]
      }
      else {
        if (this.options.indexOf(this.innerVal) === 1) {
          this.loadingUp = true
        }
        return this.options[(this.options.indexOf(this.innerVal) - 1) % 5]
      }
    },
    startLoad() {
      this.intervalID = setInterval(() => {
        this.innerVal = this.nextVal()
      }, 250);
    },
    stopLoad() {
      clearInterval(this.intervalID)
      // Continue until same value is found
      if (this.innerVal !== this.modelValue) {
        this.intervalID = setInterval(() => {
          this.innerVal = this.nextVal()
          if (this.innerVal === this.modelValue) {
            clearInterval(this.intervalID)
            this.innerVal = this.modelValue
            this.loadingUp = true
          }
        }, 250);
      }
      else {
        this.innerVal = this.modelValue
        this.loadingUp = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$width: calc(var(--width) * 1px);

.enveloppe {
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: $width;
  height: calc(.54 * $width);
}

.name {
  background-color: #FFFFFF;
  font-size: calc(.1 * $width);
  line-height: calc(.08 * $width);
  font-weight: 800;
  text-transform: uppercase;
  color: #706d6e;
  padding-left: calc(.1 * $width);
  padding-top: calc(.03 * $width);
  border-top-left-radius: calc(.11 * $width) calc(.11 * $width);
  border-top-right-radius: calc(.11 * $width) calc(.11 * $width);
}

.scoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding: calc(.03 * $width);
  border-bottom-left-radius: calc(.11 * $width) calc(.11 * $width);
  border-bottom-right-radius: calc(.11 * $width) calc(.11 * $width);
}

.note {
  font-weight: 500;
  font-size: calc(.20 * $width);
  color: rgba(255, 255, 255, .5);
  padding: 2px;
  height: calc(.3 * $width);
  width: calc(.187 * $width);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;

  &:first-child {
    border-top-left-radius: calc(.07 * $width) calc(.07 * $width);
    border-bottom-left-radius: calc(.07 * $width) calc(.07 * $width);
  }

  &:last-child {
    border-top-right-radius: calc(.07 * $width) calc(.07 * $width);
    border-bottom-right-radius: calc(.07 * $width) calc(.07 * $width);
  }

  &.selected {
    font-size: calc(.24 * $width);
    font-weight: 800;
    color: #FFFFFF;
    width: calc(.28 * $width);
    height: calc(.42 * $width);
    border: calc(.02 * $width) solid #FFFFFF;
    border-radius: calc(.15 * $width) calc(.15 * $width);
    margin-top: calc(-.04 * $width);
    margin-right: calc(-.03 * $width);
    margin-bottom: calc(-.04 * $width);
    margin-left: calc(-.03 * $width);
    z-index: 2;
  }
}

.a {
  background-color: rgb(0, 128, 67);
}

.b {
  background-color: rgb(133, 185, 49);
}

.c {
  background-color: rgb(242, 192, 17);
}

.d {
  background-color: rgb(227, 124, 19);
}

.e {
  background-color: rgb(217, 65, 26);
}
</style>