<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="auto" align-self="center">
          <h4>Nom</h4>
        </v-col>
        <v-col>
          <v-text-field v-if="!readOnly" v-model="name" density="compact" />
          <p v-if="readOnly" class="communityName">
            {{ name }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Code à partager pour rejoindre la communauté</h4>
          <p>
            <b>{{ code }}</b> (généré le {{ code_date }})
            <v-btn v-if="!readOnly" variant="outlined" class="ml-2" @click="renewCode">
              Nouveau code
            </v-btn>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>iCal URL</h4>
          <p>
            <template v-if="ical">
              <b>{{ icalUrl }}</b> (généré le {{ ical_date }})
            </template>
            <v-btn v-if="!readOnly" variant="outlined" class="ml-2" @click="renewICal">
              {{ ical_date ? 'Renouveler' : 'Générer' }}
            </v-btn>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center">
          <h4>Nombre de personnes par défaut pour les repas</h4>
        </v-col>
        <v-col cols="auto">
          <v-icon class="pr-4">
            mdi-account-group
          </v-icon>
          <int-stepper v-model="default_nb_people" :min="1" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Localisation pour la météo</h4>
          <p>Lieu actuel : {{ geocode_name ? geocode_name + ' ('+geocode_lat+', '+geocode_long+')' : 'Aucun' }}</p>
          <p>Nouveau lieu :</p>
          <v-autocomplete
            v-model="selectedGeocode"
            v-model:search="searchGeocode"
            :loading="searchLoading"
            :items="searchGeocodeResults"
            item-value="id"
            item-title="name"
            density="compact"
            hide-details
            label="Cherchez un géocode..."
            variant="outlined"
            single-line
            clearable
            return-object
            no-data-text="Aucun géocode trouvé"
            no-filter
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="!readOnly" class="text-right">
      <v-btn variant="text" color="accent" @click="removeCommunity">
        Supprimer la communauté
      </v-btn>
      <v-btn variant="text" @click="saveCommunity">
        Enregistrer les modifications
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { useRepo } from 'pinia-orm'
import { Community, CommunityRepo } from '@/models/Community'
import { UserCommunityRepo } from '@/models/UserCommunity'
import IntStepper from '@/components/IntStepper.vue'
import emitter from 'tiny-emitter/instance'
import _ from 'lodash'
import { useToast } from 'vue-toastification'

export default {
  name: "CommunityCard",
  components: [
    IntStepper
  ],
  components: { IntStepper },
  props: {
    community: {
      type: Object,
      default: () => { }
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["deleted"],
  data() {
    return {
      id: this.community.id,
      name: this.community.name,
      code: this.community.code,
      code_date: this.community.code_date,
      default_nb_people: this.community.default_nb_people,
      ical: this.community.ical,
      ical_date: this.community.ical_date,
      geocode_name: this.community.geocode_name,
      geocode_lat: this.community.geocode_lat,
      geocode_long: this.community.geocode_long,

      selectedGeocode: '',
      searchLoading: false,
      searchGeocode: '',
      searchGeocodeResults: []
    }
  },
  computed: {
    icalUrl() {
      return import.meta.env.VITE_SITE_API_ENDPOINT + '/community/ical/get.php?token=' + this.ical
    }
  },
  watch: {
    searchGeocode (val) {
      val && this.triggerSearchGeocode(val)
    },
    selectedGeocode (val) {
      if(val) {
        this.geocode_name = val.name
        this.geocode_lat = val.latitude
        this.geocode_long = val.longitude

        // reset search code
        this.searchGeocode = ''
      }
    },
  },
  methods: {
    saveCommunity() {
      useRepo(CommunityRepo).$update(this.id, {
        name: this.name,
        default_nb_people: this.default_nb_people,
        geocode_name: this.geocode_name,
        geocode_lat: this.geocode_lat,
        geocode_long: this.geocode_long
      });
    },
    removeCommunity() {
      const that = this;
      emitter.emit("showConfirm", {
        title: "Suppression",
        text: "Voulez-vous supprimer la communauté \"" + that.name + "\" ?",
        validate: async function () {
          await useRepo(CommunityRepo).$delete(that.id);
          that.$emit("deleted");
          // Deleting a community will cascade on relations so we need to update
          useRepo(UserCommunityRepo).$fetch();
        }
      });
    },
    renewCode: async function () {
      await useRepo(CommunityRepo).$renewCode(this.id);
      const newCom = useRepo(Community).find(this.id);
      this.code = newCom.code;
      this.code_date = newCom.code_date;
    },
    renewICal: async function () {
      await useRepo(CommunityRepo).$renewICal(this.id);
      const newCom = useRepo(Community).find(this.id);
      this.ical = newCom.ical;
      this.ical_date = newCom.ical_date;
    },
    triggerSearchGeocode: _.debounce(async function () {
      if(this.searchGeocode) {
        this.searchLoading = true
        try {
          const res = await useRepo(CommunityRepo).$searchGeocode(this.searchGeocode)

          // Use entities cleanup by store
          this.searchGeocodeResults = res || []
        }
        catch(err) {
          const toast = useToast()
          toast('Impossible de chercher parmis les géocodes pour le moment.', {
            type: 'error'
          })
        }

        this.searchLoading = false

      }
    }, 500),
  }
}

</script>

<style scoped lang="scss">
.communityName {
  font-size: 1.2em;
}
</style>