<template>
  <v-container fluid class="py-0 px-3 mainContent">
    <v-dialog
      v-model="storeDialog"
      scrollable
      width="700"
    >
      <v-card
        height="600"
        :loading="storeSaving"
      >
        <v-card-title class="bg-primary text-h5 text-white">
          Edition du magasin "{{ editedStore.name }}"
        </v-card-title>

        <v-card-text
          class="pt-4"
        >
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="editedStore.name"
                label="Nom"
                clearable
              />
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <v-switch
                v-model="editedStore.default_store"
                label="Sélectionné par défaut"
                hide-details
                inset
              />
            </v-col>
          </v-row>
          <h2 class="mt-3">
            Sections du magasin
          </h2>
          <v-container class="sectionContainer">
            <p
              v-if="!editedStore.sections || editedStore.sections.length === 0"
              class="noSection offset-3"
            >
              Aucune section déclarée
            </p>
            <v-row
              v-for="(s, i) in editedStore.sections"
              :key="s.name"
              dense
              class="section"
            >
              <v-col cols="3">
                <v-btn
                  icon
                  size="x-small"
                  variant="outlined"
                  class="mr-1"
                  :disabled="i === 0"
                  @click="moveSectionUp(s)"
                >
                  <v-icon size="small">
                    mdi-arrow-up-bold
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  size="x-small"
                  variant="outlined"
                  :disabled="i === editedStore.sections.length-1"
                  @click="moveSectionDown(s)"
                >
                  <v-icon size="small">
                    mdi-arrow-down-bold
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col>
                {{ s.text }}
              </v-col>
              <v-col cols="auto" align-self="center">
                <v-btn
                  v-if="!s.standard"
                  icon
                  size="x-small"
                  variant="outlined"
                  @click="removeSection(s)"
                >
                  <v-icon size="small">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              dense
              class="section"
            >
              <v-col offset="3">
                <v-text-field
                  v-model="newSectionName"
                  label="Section additionnelle"
                  clearable
                />
              </v-col>
              <v-col cols="auto" align-self="center">
                <v-btn
                  icon
                  variant="outlined"
                  size="x-small"
                  :disabled="!newSectionName"
                  @click="addSection"
                >
                  <v-icon>
                    mdi-plus-thick
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              dense
            >
              <v-col offset="3">
                <v-btn
                  @click="addDefaultSections"
                >
                  Ajouter les sections par défaut
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="footer">
          <div class="flex-grow-1" />
          <v-btn color="primary" variant="text" @click="cancelStoreEdition">
            Annuler
          </v-btn>
          <v-btn color="primary" variant="flat" @click="saveStore">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showPrintDialog"
      scrollable
      width="700"
    >
      <v-card
        height="400"
        :loading="storeSaving"
      >
        <v-card-title class="bg-primary text-h5 text-white">
          Impression de cartes spéciales
        </v-card-title>

        <v-card-text
          class="pt-4"
        >
          <v-row dense>
            <v-col align-self="center">
              Nombre de cartes "Restes" :
            </v-col>
            <v-col>
              <int-stepper
                v-model="leftovers"
                :min="0"
                @update:modelValue="(v) => { addToPrint('leftover', v) }"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center">
              Nombre de cartes "Restaurant" :
            </v-col>
            <v-col>
              <int-stepper
                v-model="restaurants"
                :min="0"
                @update:modelValue="(v) => { addToPrint('restaurant', v) }"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="text-right">
          <v-btn variant="text" class="mr-2" @click="clearPrint">
            Annuler
          </v-btn>
          <print-button
            v-if="leftovers > 0 || restaurants > 0"
            @printed="clearPrint"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row cols="auto" class="my-0">
      <v-col cols="12" sm="auto" width="400px" class="leftDrawer">
        <user-card :key="currentUser.id" :user="currentUser" class="mb-3" />
        <p class="text-h6 text-center mt-6">
          Communautés
        </p>
        <v-list avatar rounded class="pa-2">
          <v-list-item
            v-for="item in communities"
            :key="item.id"
            :value="item"
            rounded="xl"
            color="primary"
            @click="selectCommunity(item)"
          >
            <template #prepend>
              <v-icon
                color="primary"
                class="mr-2"
              >
                {{ item.id === editedCommunity.id ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
              </v-icon>
            </template>

            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <div>
          <v-text-field
            v-model="communityCode"
            variant="outlined"
            label="Rejoindre par code"
            class="mt-3"
            bg-color="background"
            single-line
            clearable
          >
            <template #append>
              <v-btn
                icon
                size="x-small"
                :disabled="!communityCode"
                @click="joinCommnity"
              >
                <v-icon size="large">
                  mdi-account-multiple-plus
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <template v-if="$vuetify.display.xs">
            <p>
              Code de cette communauté : <b>{{ editedCommunity.code ? editedCommunity.code : '-' }}</b>
            </p>
          </template>
        </div>
        <div v-if="$vuetify.display.smAndUp" class="text-center mt-3">
          <v-btn color="primary" @click="newCommunity">
            <v-icon>
              mdi-plus
            </v-icon>
            Nouvelle communauté
          </v-btn>
        </div>
      </v-col>
      <v-col v-if="$vuetify.display.smAndUp && !editedCommunity.id">
        <p class="text-center mt-12">
          Sélectionnez une communauté
        </p>
      </v-col>

      <v-col v-if="$vuetify.display.smAndUp && editedCommunity.id">
        <community-card :key="editedCommunity.id" :read-only="!isCommunityAdmin" :community="editedCommunity" class="mb-3" @deleted="resetEdition" />
        <EasyDataTable
          :headers="cuHeaders"
          :items="editedCommunityUsers"
          item-key="id"
          hide-footer
          sort-by="name"
        >
          <template #item-avatar="item">
            <v-img :src="'/avatar/'+(item.avatar || 'food-biscuit')+'.png'" eager height="24px" width="24px" />
          </template>

          <template #item-is_admin="item">
            {{ item.is_admin ? "Oui" : "Non" }}
          </template>

          <template #item-action="item">
            <div class="d-flex flex-nowrap">
              <v-btn
                icon
                variant="text"
                size="x-small"
                :disabled="!isCommunityAdmin"
                @click="deleteUC(item)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
        </EasyDataTable>
        <EasyDataTable
          :headers="sHeaders"
          :items="editedStores"
          item-key="id"
          hide-footer
          sort-by="name"
          class="mt-4"
        >
          <template #item-default_store="item">
            <v-switch
              :model-value="item.default_store"
              hide-details
              inset
              disabled
              class="mt-0"
            />
          </template>

          <template #item-sections="item">
            {{ item.sections.map(a => a.text).join(', ') }}
          </template>

          <template #item-action="item">
            <div class="d-flex flex-nowrap">
              <v-btn
                icon
                variant="text"
                size="x-small"
                :disabled="!isCommunityAdmin"
                @click="editStore(item)"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                icon
                variant="text"
                size="x-small"
                :disabled="!isCommunityAdmin"
                @click="deleteStore(item)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
        </EasyDataTable>
        <v-container class="text-center">
          <v-btn
            variant="outlined"
            :disabled="!isCommunityAdmin"
            :loading="loadingNewStore"
            @click="addStore"
          >
            Ajouter un magasin
          </v-btn>
        </v-container>
        <v-container class="text-center">
          <v-btn
            variant="outlined"
            @click="openPrintDialog"
          >
            <v-icon class="mr-2">
              mdi-printer
            </v-icon>
            Imprimer des cartes spéciales
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import UserCard from "@/components/UserCard.vue"
import CommunityCard from "@/components/CommunityCard.vue"
import IntStepper from "@/components/IntStepper.vue"
import PrintButton from "@/components/PrintButton.vue"
import { useRepo } from "pinia-orm"
import { Globalconfig } from "@/models/Globalconfig"
import { Community, CommunityRepo } from "@/models/Community"
import { User, UserRepo } from "@/models/User"
import { UserCommunity, UserCommunityRepo } from "@/models/UserCommunity"
import { Store, StoreRepo } from "@/models/Store"
import { Ingredient } from "@/models/Ingredient"
import { EntityToPrint } from '@/models/EntityToPrint'
import { useToast } from 'vue-toastification'
import emitter from 'tiny-emitter/instance'
import _ from "lodash"

export default {
  name: "UsersView",
  components: {
    UserCard,
    CommunityCard,
    IntStepper,
    PrintButton
  },
  data: () => ({
    editedCommunity: {},
    cuHeaders: [
      { text: 'Avatar', value: 'avatar', width: 70},
      { text: 'Nom', value: 'name', sortable: true},
      { text: 'Admin', value: 'is_admin', width: 70},
      { text: 'Action', value: 'action', width: 70}
    ],
    sHeaders: [
      { text: 'Magasin', value: 'name', sortable: true },
      { text: 'Sélectionné par défaut', value: 'default_store'},
      { text: 'Rayons', value: 'sections'},
      { text: 'Action', value: 'action', width: 70}
    ],
    selected: {},
    communityCode: '',
    loadingNewStore: false,
    storeDialog: false,
    editedStore: {},
    storeSaving: false,
    newSectionName: '',

    showPrintDialog: false,
    leftovers: 0,
    restaurants: 0
  }),
  computed: {
    currentUser() {
      return User.getCurrentUser() || {}
    },
    currentCommunity() {
      const config = useRepo(Globalconfig).find("currentCommunity")
      if (config) {
        return config.value
      } else {
        return {}
      }
    },
    editedCommunityUsers() {
      const cId = this.editedCommunity.id
      if (cId) {
        const relations = useRepo(UserCommunity)
          .with('user')
          .where('community_id', cId)
          .get()
        // Need to filter manually because numeric ids are not filtered correctly in whereHas sub-query result (using where.$id gets string id)
        // Maybe linked to https://github.com/vuex-orm/vuex-orm/issues/433
        const copy = [...new Set(relations.filter(r => !!r.user).map(r => r.user))]
        // Adding an "is_admin" calculated field
        copy.map(user => {
          user.is_admin = relations.filter(r => r.user_id === user.id && r.is_admin).length > 0
        })
        return copy
      } else {
        return []
      }
    },
    editedStores() {
      const cId = this.editedCommunity.id
      if (cId) {
        const stores = useRepo(Store)
          .where('community_id', cId)
          .get()
        return stores
      } else {
        return []
      }
    },
    isCommunityAdmin() {
      return this.editedCommunityUsers.filter(u => u.id === this.currentUser.id && u.is_admin).length === 1
    },
    communities() {
      return useRepo(Community).all()
    }
  },
  mounted: async function() {

    useRepo(UserRepo).$fetch()
    useRepo(UserCommunityRepo).$fetch()
    useRepo(CommunityRepo).$fetch()

    // Bootstrap with first community only once after loadings
    if(!this.editedCommunity || !this.editedCommunity.id) {
      this.editedCommunity = this.currentCommunity
    }

    useRepo(StoreRepo).$fetchForCommunity(this.editedCommunity.id)
  },
  methods: {
    selectCommunity(item) {
      this.editedCommunity = item
      useRepo(StoreRepo).$fetchForCommunity(item.id)
    },
    newCommunity: async function() {
      const newC = await useRepo(CommunityRepo).$create({
        name: 'Nouvelle communauté - '+this.currentUser.name
      })
      // Community creation creates relations: need to update
      await useRepo(UserCommunityRepo).$fetch()
      // Getting from store to ensure type of data
      this.editedCommunity = useRepo(Community).find(newC.id)
    },
    resetEdition() {
      if(this.currentCommunity) {
        this.editedCommunity = this.currentCommunity
      }
    },
    joinCommnity() {
      if(this.communityCode) {
        useRepo(CommunityRepo).$join(this.communityCode)
          .then(res => {
            // Reload current user defintion to get updated community access rights
            useRepo(UserRepo).$loadCurrentUser()
          })
          .catch(err => {
            const toast = useToast()
            toast('Impossible de rejoindre une communauté avec ce code. Vérifiez que vous n\'en faites pas déjà partie.', {
              type: 'error'
            })
          })
      }
    },
    deleteUC(user) {
      emitter.emit('showConfirm', {
        title: 'Suppression',
        text: 'Voulez-vous supprimer l\'utilisateur "'+user.name+'" de la communauté "'+this.editedCommunity.name+'" ?',
        validate:() => {
          const cId = this.editedCommunity.id
          const uId = user.id
          const uc = useRepo(UserCommunity)
                      .where('user_id', uId)
                      .where('community_id', cId)
                      .get()
          if(uc && uc.length === 1) {
            useRepo(UserCommunityRepo).$delete(uc[0].id)
          }
          else {
            const toast = useToast()
            toast('Impossible d\'effectuer cette suppression.', {
              type: 'error'
            })
          }
        }
      })
    },
    deleteStore(store) {
      emitter.emit('showConfirm', {
        title: 'Suppression',
        text: 'Voulez-vous supprimer le magasin "'+store.name+'" de la communauté "'+this.editedCommunity.name+'" ?',
        validate:() => {
          useRepo(StoreRepo).$delete(store.id)
        }
      })
    },
    async addStore() {
      const store = {
        ...new Store(),
        community_id: this.editedCommunity.id
      }
      delete store.id
      this.loadingNewStore = true
      await useRepo(StoreRepo).$create(store)
      this.loadingNewStore = false
    },
    editStore(item) {
      this.editedStore = {
        ...item
      }
      this.storeDialog = true
    },
    cancelStoreEdition() {
      this.storeDialog = false
      this.editedStore = {}
    },
    async saveStore() {
      this.storeSaving = true
      await useRepo(StoreRepo).$update(this.editedStore.id, this.editedStore)
      this.storeSaving = false
      this.storeDialog = false
    },
    addDefaultSections() {
      this.editedStore.sections = _.uniqWith(
        Ingredient.allTypes().concat(this.editedStore.sections),
        (a, b) => {
          return a.value === b.value
        }
      )
    },
    addSection() {
      this.editedStore.sections.push({
        text: this.newSectionName,
        value: this.newSectionName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\W/g,'_')
      })
      this.newSectionName = ''
    },
    removeSection(section) {
      this.editedStore.sections = this.editedStore.sections.filter((s) => s.value !== section.value)
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
    moveSectionUp(section) {
      const i = this.editedStore.sections.findIndex((s) => s.value === section.value)
      if(i > 0) {
        this.array_move(this.editedStore.sections, i, i-1)
      }
    },
    moveSectionDown(section) {
      const i = this.editedStore.sections.findIndex((s) => s.value === section.value)
      if(i > -1 && i < this.editedStore.sections.length-1) {
        this.array_move(this.editedStore.sections, i, i+1)
      }
    },
    openPrintDialog() {
      useRepo(EntityToPrint).flush()
      this.showPrintDialog = true
    },
    addToPrint(type, value) {
      if(type === 'leftover') {
        this.leftovers = value
      }
      if(type === 'restaurant') {
        this.restaurants = value
      }
      const newETP = useRepo(EntityToPrint).make()
      useRepo(EntityToPrint).save({
        ...newETP,
        entity_type: 'custom',
        entity_data: {
          preset: type
        }
      })
    },
    clearPrint() {
      useRepo(EntityToPrint).destroy((rtp) => rtp.entity_type === 'custom')
      this.leftovers = 0
      this.restaurants = 0
      this.showPrintDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.mainContent {
  min-height: 100%;
  display: flex;

  .leftDrawer {
    background: rgb(var(--v-theme-secondary));
  }
}

.addSection {
  min-width: 32px !important;
}

.noSection {
  font-style: italic;
}
.sectionContainer {
  width: 400px;
}

.footer {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .3);
  z-index: 1;
}
</style>