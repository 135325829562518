<template>
  <v-container fluid class="fill-height pb-0">
    <v-dialog
      v-model="dialogView"
      width="950"
      :fullscreen="$vuetify.display.smAndDown"
      scrollable
    >
      <recipe-details
        v-if="$vuetify.display.smAndUp"
        :key="editedItem.id || Date.now()"
        :recipe="editedItem"
        :title="formTitle"
        @close="dialogView = false"
        @edit="editItem(editedItem)"
        @remove="removeItem(editedItem)"
        @update-score="(score)=> editedItem.score = score"
      />
      <recipe-details-mobile
        v-if="$vuetify.display.xs"
        :key="editedItem.id || Date.now()"
        :recipe="editedItem"
        :title="formTitle"
        @close="dialogView = false"
        @edit="editItem(editedItem)"
        @update-score="(score)=> editedItem.score = score"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogEdit"
      min-width="60vw"
      :fullscreen="$vuetify.display.smAndDown"
      scrollable
      persistent
    >
      <recipe-edition
        :key="editedItem.id || Date.now()"
        :recipe="editedItem"
        :extra-ingredients="editedItem.extraIngredients ? editedItem.extraIngredients : ''"
        :title="formTitle"
        :creating="creating"
        @cancel="close"
        @validate="validate"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogImport"
      min-width="60vw"
      :fullscreen="$vuetify.display.xs"
      scrollable
    >
      <recipe-online-search
        @create="addItem"
        @close="dialogImport = false"
      />
    </v-dialog>

    <v-dialog
      v-if="showScanner"
      v-model="showScanner"
      :fullscreen="$vuetify.display.smAndDown"
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title class="text-h5 bg-primary text-white">
          Scannez une recette
          <v-spacer v-if="$vuetify.display.smAndDown" />
          <span v-if="$vuetify.display.smAndDown">
            <v-btn
              icon
              @click="showScanner = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </span>
        </v-card-title>

        <v-card-text class="pa-0">
          <live-scanner
            :active-scan="showScanner"
            @select="selectFromScan"
          />
        </v-card-text>

        <v-card-actions v-if="$vuetify.display.mdAndUp">
          <v-spacer />
          <v-btn
            variant="text"
            @click="showScanner = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <recipe-list ref="list" :loading-recipe="loadingRecipe" @clicked-recipe="handleClick">
      <template #buttons>
        <print-button
          v-if="entitiesToPrint.length > 0"
        />
        <v-btn
          v-if="$vuetify.display.smAndUp"
          v-tooltip:left="'Ajouter une recette'"
          icon
          variant="outlined"
          color="primary"
          class="mr-2 ligthenBg"
          title="Ajouter une recette"
          size="small"
          :disabled="isOffline"
          @click="addItem"
        >
          <v-icon size="large">
            mdi-plus
          </v-icon>
        </v-btn>
        <v-btn
          v-tooltip:bottom="'Chercher en ligne'"
          icon
          variant="outlined"
          color="primary"
          title="Chercher en ligne"
          size="small"
          class="ligthenBg mr-2"
          :disabled="isOffline"
          @click="importItem"
        >
          <v-icon size="large">
            mdi-cloud-search
          </v-icon>
        </v-btn>
        <v-btn
          v-if="canScanCode === true"
          v-tooltip:bottom="'Scanner un code'"
          icon
          variant="outlined"
          color="primary"
          title="Scanner un code"
          size="small"
          class="ligthenBg mr-2"
          :disabled="isOffline"
          @click="startScanner"
        >
          <v-icon size="large">
            mdi-qrcode-scan
          </v-icon>
        </v-btn>
        <VDropdown
          trigger="click"
          class="extraAction"
          container="#app"
        >
          <template #popper>
            <v-container class="background text-center pa-2">
              <v-row dense>
                <v-col>
                  <v-btn
                    variant="outlined"
                    @click="toggleSelectionMode"
                  >
                    <v-icon class="mr-2">
                      {{ selectionMode ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                    </v-icon>
                    Mode sélection pour impression
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <v-badge
            v-model="selectionMode"
            dot
            bordered
            color="primary"
          >
            <v-btn
              icon
              variant="outlined"
              size="small"
              class="ligthenBg"
              :disabled="isOffline"
            >
              <v-icon size="large">
                mdi-dots-horizontal
              </v-icon>
            </v-btn>
          </v-badge>
        </VDropdown>
      </template>
    </recipe-list>
  </v-container>
</template>

<script>
import _ from "lodash"
import QrScanner from 'qr-scanner'
import { useRepo } from "pinia-orm"
import { Globalconfig } from "@/models/Globalconfig"
import { Recipe, RecipeRepo } from '@/models/Recipe'
import { EntityToPrint } from '@/models/EntityToPrint'
import RecipeList from '@/components/RecipeList'
import RecipeOnlineSearch from '@/components/RecipeOnlineSearch'
import RecipeEdition from '@/components/RecipeEdition'
import RecipeDetails from '@/components/RecipeDetails'
import RecipeDetailsMobile from '@/components/RecipeDetailsMobile'
import PrintButton from '@/components/PrintButton'
import LiveScanner from '@/components/LiveScanner'
import emitter from 'tiny-emitter/instance'

export default {
  name: 'RecipesView',
  components: {
    RecipeList,
    RecipeOnlineSearch,
    RecipeEdition,
    RecipeDetails,
    RecipeDetailsMobile,
    PrintButton,
    LiveScanner
  },
  data: () => ({
    editedItem: {},
    creating: false,
    dialogEdit: false,
    dialogImport: false,
    dialogView: false,
    loadingRecipe: 0,

    selectionMode: false,

    showScanner: false,
    canScanCode: undefined
  }),
  computed: {
    isOffline() {
      return useRepo(Globalconfig).find('isOffline').value
    },
    formTitle () {
      return this.creating ? 'Nouvelle recette' : 'Edition de la recette'
    },
    entitiesToPrint () {
      return useRepo(EntityToPrint).all()
    }
  },
  mounted: async function () {
    if(this.$route.query.id) {
      this.displayRecipe(this.$route.query.id)
    }

    this.checkCameraAvailability()
  },
  methods: {
    selectFromScan(recipe) {
      this.showScanner = false
      this.displayRecipe(recipe)
    },
    async displayRecipe (recipeId) {
      await useRepo(RecipeRepo).$get(recipeId)
      this.viewItem({ id: recipeId})
    },
    async checkCameraAvailability() {
      QrScanner.hasCamera().then(val => {
        this.canScanCode = true
      })
      .catch(err => {
        this.canScanCode = false
      })
    },
    startScanner() {
      this.showScanner = true
    },
    addItem (bootstrapData) {
      this.creating = true
      if(bootstrapData) {
        this.editedItem = {
          ...new Recipe(),
          ...bootstrapData
        }
      }
      else {
        this.editedItem = {...new Recipe()}
      }
      this.dialogEdit = true
    },
    handleClick: async function(item) {
      if(this.selectionMode) {
        if(useRepo(EntityToPrint).where('entity_id', item.id).get().length > 0) {
          useRepo(EntityToPrint).where((rtp) => rtp.entity_id === item.id).delete()
        }
        else {
          useRepo(EntityToPrint).save({
            entity_id: item.id,
            entity_type: 'recipe'
          })
        }
      }
      else {
        this.loadingRecipe = item.id
        await this.viewItem(item)
        this.loadingRecipe = 0
      }
    },
    viewItem: async function (item) {
      if(!this.isOffline) {
        await useRepo(RecipeRepo).$loadIngredients(item.id)
      }
      this.editedItem = useRepo(Recipe).withAllRecursive(2).find(item.id)
      this.dialogView = true
    },
    editItem: async function (item) {
      this.dialogView = false
      this.creating = false
      await useRepo(RecipeRepo).$loadIngredients(item.id)
      this.editedItem = _.cloneDeep(useRepo(Recipe).withAllRecursive(2).find(item.id))
      this.dialogEdit = true
    },
    importItem () {
      this.creating = true
      this.editedItem = {...new Recipe()}
      this.dialogImport = true
    },
    removeItem (item) {
      const that = this

      emitter.emit('showConfirm', {
        title: 'Suppression',
        text: 'Voulez-vous supprimer la recette "'+item.name+'" ? Attention : tous les repas l\'utilisant ne pourront plus y faire référence.',
        validate: async function() {
          await useRepo(RecipeRepo).$delete(item.id)
          that.dialogView = false
          that.$refs.list.reload()
        }
      })
    },
    validate () {
      if(this.creating) {
        this.$refs.list.reload()
      }
      this.close()
    },
    close () {
      this.dialogEdit = false
      setTimeout(() => {
        this.editedItem = {}
      }, 300)
    },
    toggleSelectionMode () {
      this.selectionMode = !this.selectionMode
      if(this.selectionMode === false) {
        useRepo(EntityToPrint).flush()
      }
    },
    async printIt () {
      await Promise.all(
        useRepo(EntityToPrint).all().map((r) => {
          return new Promise((resolve, reject) => {
            useRepo(RecipeRepo).$loadIngredients(r.recipe_id).then(resolve).catch(reject)
          })
        })
      )

      this.showPrint = true

      setTimeout(() => {
        const ref = document.getElementsByClassName('printArea')[0]
        const clone = ref.cloneNode(true)
        // Special copy for canvas
        Array.from(ref.getElementsByClassName('barcode')).forEach((src) => {
          let classId
          src.classList.forEach((cl) => {
            if(cl.indexOf('barcode_') === 0) {
              classId = cl
            }
          })
          const dest = clone.getElementsByClassName(classId)[0]
          //set dimensions
          dest.width = src.width;
          dest.height = src.height;

          //apply the old canvas to the new one
          dest.getContext('2d').drawImage(src, 0, 0);
        })

        const realPrintable = document.createElement('div')
        realPrintable.id = 'tmpPrint'
        document.body.appendChild(realPrintable)

        realPrintable.appendChild(clone)

        window.print()
      }, 500);
    }
  }
}
</script>

<style lang="scss">
.printDialog {
  width: 21cm;
  display: block;
  width: auto;
  height: auto;
}
.printArea {
  width: 21cm;
  min-height: 29.7cm;
  background: white;
  padding: .5cm;
  margin: 0;
  overflow: visible;
  box-shadow: none;

  .printCard {
    margin-bottom: .5cm;

    &:nth-of-type(2n+1) {
      margin-right: .5cm;
    }
  }
}

.extraAction {
  display: inline-block;
  vertical-align: middle;
}
</style>