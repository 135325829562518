<template>
  <v-container fluid class="fill-height pb-0 px-0 align-start">
    <storage-edition
      :key="editedStorageId ? 'storage_'+editedStorageId : newStorageId"
      :new="createStorage"
      :storage-id="editedStorageId"
      :show="showStorageDialog"
      @close="closeStorageDialog"
    />
    <stored-item-edition
      :key="editedStoredItemId ? 'storedItem_'+editedStoredItemId : newStoredItemId"
      :new="createStoredItem"
      :stored-item-id="editedStoredItemId"
      :show="showStoredItemDialog"
      :default-stack="createStoredItemDefaultStack"
      :max-stack="selectedStorage ? selectedStorage.nb_stacks : 1"
      :storage-id="selectedStorage ? selectedStorage.id : 0"
      @close="closeStoredItemDialog"
    />

    <v-row
      v-if="$vuetify.display.smAndDown"
      dense
      class="mx-0 mt-n3 pt-3 mb-2 sticky mobileStorageRow rowMaxWidth"
    >
      <v-col cols="12" class="scrollbars mobileStorageContainer">
        <storage-card
          v-for="s in storages"
          :key="'storage_'+s.id"
          :storage-id="s.id"
          :selected="selectedStorage && selectedStorage.id === s.id"
          class="ma-2 mobileStorage"
          :select-button="false"
          :dense="true"
          @edit="editStorage(s.id)"
          @select="selectStorage(s.id)"
          @remove="removeStorage(s.id)"
        />
        <v-btn
          width="100px"
          class="ma-2 mobileAddStorage rounded-lg"
          :loading="loadingStorage"
          @click="addStorage"
        >
          <v-icon size="large">
            mdi-plus-thick
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense class="ma-0 rowMaxWidth">
      <v-col
        v-if="$vuetify.display.mdAndUp"
        width="350px"
        cols="auto"
        class="pa-8 pr-2"
      >
        <v-card
          rounded="xl"
          class="pa-3"
          width="350px"
        >
          <p class="text-h5 text-center">
            Stockages disponibles
          </p>
          <div class="text-center">
            <VDropdown>
              <v-btn
                v-if="storedItemsSoonExpired.length > 0"
                size="small"
                variant="text"
                :color="storedItemsSoonExpired.length>0 ? 'accent' : 'primary'"
              >
                <v-icon class="mr-2">
                  mdi-calendar-alert
                </v-icon>
                Expirés dans les 15 jours : {{ storedItemsSoonExpired.length }}
              </v-btn>
              <template #popper>
                <v-list
                  width="300"
                  class="expiredLists scrollbars text-left"
                >
                  <v-list-item
                    v-for="item in storedItemsSoonExpired"
                    :key="item.id"
                    link
                    :avatar="getStorageIcon(item)"
                    @click="showStoredItem(item.id)"
                  >
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.storage ? item.storage.name : '' }}
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </template>
            </VDropdown>
          </div>
          <storage-card
            v-for="s in storages"
            :key="'storage_'+s.id"
            :storage-id="s.id"
            :selected="selectedStorage && selectedStorage.id === s.id"
            class="ma-2"
            @edit="editStorage(s.id)"
            @select="selectStorage(s.id)"
            @remove="removeStorage(s.id)"
          />
          <div class="text-center">
            <v-btn
              variant="outlined"
              width="200px"
              class="ma-2"
              :loading="loadingStorage"
              @click="addStorage"
            >
              <v-icon size="x-large">
                mdi-plus-thick
              </v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col class="pa-0">
        <v-autocomplete
          v-model="searchSelectedId"
          :disabled="loadingStorage"
          :items="allStoredItems"
          chips
          color="blue-grey-lighten-2"
          label="Chercher un item dans tous les stockages..."
          item-title="name"
          item-value="id"
          variant="outlined"
          single-line
          hide-details
          class="mb-6 mx-2"
          @update:model-value="searchSelection"
        >
          <template #selection="{ props, item }">
            <v-chip
              v-bind="props"
              :prepend-icon="getStorageIcon(item.raw)"
              closable
              @click:close="searchSelectedId = 0"
            >
              <span class="searchSelectedText" :title="item.raw.name + (item.raw.storage ? '[' + item.raw.storage.name + ']' : '')">
                {{ item.raw.name }} {{ item.raw.storage ? '['+item.raw.storage.name+']' : '' }}
              </span>
            </v-chip>
          </template>
          <template #item="{ props, item }">
            <v-list-item :prepend-icon="getStorageIcon(item.raw)" v-bind="props">
              <v-list-item-subtitle>
                {{ item.raw.storage ? item.raw.storage.name : '' }}
              </v-list-item-subtitle>
            </v-list-item>
          </template>
        </v-autocomplete>
        <div class="pa-2">
          <v-expansion-panels
            v-if="selectedStorage"
            v-model="currentOpenedPanel"
            variant="accordion"
          >
            <v-expansion-panel
              v-for="(stack, index) in selectedStorageContent"
              :key="stack.index"
              class="mb-2"
              @group:selected="expansionPanelChange"
            >
              <v-expansion-panel-title>
                Etage {{ selectedStorageContent.length-index }} ({{ stack.items.length }} items)
              </v-expansion-panel-title>
              <v-expansion-panel-text class="pt-6" eager>
                <v-row
                  v-masonry
                  dense
                >
                  <v-col
                    v-for="item in stack.items"
                    :key="'storedItem_'+item.id"
                    v-masonry-tile
                    cols="12"
                    md="6"
                  >
                    <stored-item-card
                      class="pa-2"
                      :stored-item-id="item.id"
                      @edit="editStoredItem(item.id)"
                      @remove="removeStoredItem(item.id)"
                      @copy="copyStoredItem(item.id)"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col v-if="stack.items.length === 0" cols="12">
                    <p class="ma-0 emptyStack">
                      Vide
                    </p>
                  </v-col>
                  <v-col cols="12" class="text-center mt-2">
                    <v-btn
                      variant="outlined"
                      width="200px"
                      :loading="duplicating"
                      @click="addStoredItem(selectedStorageContent.length-index)"
                    >
                      <v-icon size="x-large">
                        mdi-plus-thick
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import { useRepo } from 'pinia-orm'
import { Storage, StorageRepo } from '@/models/Storage'
import { StoredItem, StoredItemRepo } from '@/models/StoredItem'
import StorageCard from '@/components/StorageCard'
import StoredItemCard from '@/components/StoredItemCard'
import StorageEdition from '@/components/StorageEdition'
import StoredItemEdition from '@/components/StoredItemEdition'
import emitter from 'tiny-emitter/instance'

export default {
  name: 'StorageView',
  components: {
    StorageCard,
    StoredItemCard,
    StorageEdition,
    StoredItemEdition
  },
  data: () => ({
    selectedStorage: null,
    searchSelectedId: 0,
    loadingStorage: false,
    currentOpenedPanel: 0,

    createStorage: false,
    editedStorageId: 0,
    newStorageId: 'storage_'+Date.now(),
    showStorageDialog: false,

    createStoredItem: false,
    createStoredItemDefaultStack: 0,
    editedStoredItemId: 0,
    newStoredItemId: 'storedItem_'+Date.now(),
    showStoredItemDialog: false,
    duplicating: false
  }),
  computed: {
    storages() {
      return useRepo(Storage).all()
    },
    selectedStorageContent () {
      const content = []
      if(this.selectedStorage) {
        let i
        for(i=0 ; i < this.selectedStorage.nb_stacks ; i++) {
          content.push({
            items: useRepo(StoredItem)
                            .where('stack', this.selectedStorage.nb_stacks-i-1)
                            .where('storage_id', this.selectedStorage.id)
                            .orderBy('expiration_date', 'asc')
                            .get()
            }
          )
        }
      }
      return content
    },
    storedItemsSoonExpired() {
      return useRepo(StoredItem).where((si) => {
        return moment(si.expiration_date).isBefore(moment(), 'day')
      }).with('storage').get()
    },
    allStoredItems() {
      return useRepo(StoredItem).with('storage').get()
    }
  },
  async mounted () {
    if(this.$vuetify.display.smAndDown) {
      this.showPanel = false
    }

    this.loadingStorage = true
    await Promise.all([
      useRepo(StoredItemRepo).$fetch(),
      useRepo(StorageRepo).$fetch()
    ])
    if (useRepo(Storage).all().length > 0) {
      this.selectedStorage = useRepo(Storage).all()[0]
    }
    this.loadingStorage = false
  },
  methods: {
    getStorageIcon(storedItem) {
      const storage = useRepo(Storage).find(storedItem.storage_id)
      if(!storage) return ''
      return Storage.allTypes().filter(s => s.value === storage.type)[0].icon
    },
    addStorage() {
      this.createStorage = true
      this.newStorageId = 'storage_'+Date.now()
      this.showStorageDialog = true
    },
    addStoredItem(defaultStack) {
      this.createStoredItem = true
      this.newStoredItemId = 'storedItem_'+Date.now()
      this.createStoredItemDefaultStack = defaultStack
      this.showStoredItemDialog = true
    },
    editStorage(storageId) {
      this.createStorage = false
      this.editedStorageId = storageId
      this.showStorageDialog = true
    },
    editStoredItem(storedItemId) {
      this.createStoredItem = false
      this.editedStoredItemId = storedItemId
      this.showStoredItemDialog = true
    },
    selectStorage(storageId) {
      this.selectedStorage = useRepo(Storage).find(storageId)
    },
    closeStorageDialog() {
      this.editedStorageId = 0
      this.showStorageDialog = false
    },
    closeStoredItemDialog() {
      this.editedStoredItemId = 0
      this.showStoredItemDialog = false
    },
    removeStorage(storageId) {
      const storage = useRepo(Storage).find(storageId)

      emitter.emit('showConfirm', {
        title: 'Suppression',
        text: 'Voulez-vous supprimer le stockage "'+storage.name+'" ?',
        validate: async () => {
          await useRepo(StorageRepo).$delete(storage.id)
        }
      })
    },
    removeStoredItem(storedItemId) {
      const storedItem = useRepo(StoredItem).find(storedItemId)

      emitter.emit('showConfirm', {
        title: 'Suppression',
        text: 'Voulez-vous supprimer l\'item "'+storedItem.name+'" ?',
        validate: async () => {
          await useRepo(StoredItemRepo).$delete(storedItem.id)
        }
      })
    },
    async copyStoredItem(storedItemId) {
      this.duplicating = true
      const storedItem = useRepo(StoredItem).find(storedItemId)
      const storedItemCopy = {
        ...storedItem
      }
      delete storedItemCopy.id
      storedItemCopy.name += ' - Copie'
      await useRepo(StoredItemRepo).$create(storedItemCopy)
      this.expansionPanelChange()
      this.duplicating = false
    },
    expansionPanelChange() {
      setTimeout(() => {
        this.$redrawVueMasonry()
      }, 100);
    },
    showStoredItem(id) {
      if(id) {
        const storedItem = useRepo(StoredItem).find(id)
        this.selectStorage(storedItem.storage_id)
        const storage = useRepo(Storage).find(storedItem.storage_id)
        this.currentOpenedPanel = storage.nb_stacks - storedItem.stack - 1
        this.expansionPanelChange()
      }
    },
    searchSelection() {
      if(this.searchSelectedId) {
        this.showStoredItem(this.searchSelectedId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.emptyStack {
  text-align: center;
  font-style: italic;
}
.rowMaxWidth {
  max-width: 100% !important;
}

.mobileStorageRow {
  z-index: 2;
  background-color: rgb(var(--v-theme-background));
}
.mobileStorageContainer {
  white-space: nowrap;
  overflow: auto;
  display: flex;
  align-items: stretch;

  .mobileAddStorage {
    background-color: #FFFFFF;
    height: auto;
  }
  .mobileStorage {
    max-width: 70vw;
    min-width: 300px;
    display: inline-block;
  }
}
.searchSelectedText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.expiredLists {
  max-height: 300px;
  overflow: auto;
}
</style>