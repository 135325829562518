<template>
  <v-container
    fluid
    :class="'pa-0 flex-column align-stretch fill-height ' + (itemsToDisplay.length > 0 ? 'filled' : 'empty')"
  >
    <div
      class="flex-grow-0 flex-shrink-0 mb-2 max-width"
    >
      <v-row dense>
        <v-col cols="auto" align-self="center">
          <slot name="prepend-buttons" />
        </v-col>
        <v-col v-if="hasSearch" align-self="center">
          <v-text-field
            v-model="search"
            label="Rechercher"
            single-line
            hide-details
            clearable
            density="compact"
            @update:model-value="updateSearch"
            @click:clear="clearSearch"
          />
        </v-col>
        <v-col cols="auto" align-self="center">
          <slot name="buttons" />
        </v-col>
      </v-row>
      <slot name="extraHeader" />
    </div>
    <div
      class="flex-grow-1 listContent scrollbars max-width"
    >
      <v-row class="my-0">
        <v-col
          v-for="item in itemsToDisplay"
          :key="item.id"
          :cols="_colsPerRow"
        >
          <slot name="item" :item="item" />
        </v-col>
      </v-row>
      <div v-if="internalLoading || loading" class="text-center text-h5 loadingText">
        Chargement
      </div>
      <div v-if="!loading && !internalLoading && !nothingToLoad" v-intersect="onIntersect" class="interactor" />
      <v-row
        v-if="!internalLoading && !loading && itemsToDisplay.length === 0"
        align="start"
        justify="center"
        class="listPlaceholder"
      >
        <v-col cols="12" class="text-center">
          <slot name="empty" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'FullPageList',
    components: {
    },
    props: {
      items: {
        type: Array,
        default: null
      },
      loadItems: {
        type: Function,
        default: null
      },
      filters: {
        type: Object,
        default: ()=>{}
      },
      hasSearch: {
        type: Boolean,
        default: true
      },
      searchProperties: {
        type: Array,
        default: null
      },
      perPage: {
        type: Number,
        default: 10
      },
      colsPerRow: {
        type: Number,
        default: 0
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    emits: ['clickedItem'],
    data: () => ({
      internalLoading: false,
      nothingToLoad: false,
      offset: 0,
      search: '',
      filteredItems: [],
      itemsToDisplay: []
    }),
    computed: {
      currentBreakpoint () {
        const bps = ['xs', 'sm', 'md', 'lg', 'xl']
        const bp = bps.filter(b => this.$vuetify.display[b])
        return bp.length ? bp[0] : '';
      },
      _colsPerRow () {
        let cpr = 12
        if(this.colsPerRow === 0) {
          switch(this.currentBreakpoint) {
            case 'sm':
              cpr = 6
              break
            case 'md':
              cpr = 4
              break
            case 'lg':
            case 'xl':
              cpr = 3
              break
            default:
              cpr = 12
          }
        }
        return cpr
      }
    },
    watch: {
      items() {
        this.offset = 0
        this.nothingToLoad = false
        this._loadItems()
        if(this.search) {
          this.updateSearch(this.search)
        }
      },
      filters: {
        handler(newVal, oldVal) {
          if(JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.reload()
          }
        },
        deep: true
      }
    },
    created() {
    },
    mounted () {
    },
    methods: {
      _loadItems: async function () {
        if(!this.internalLoading) {
          this.internalLoading = true

          if(this.loadItems) {
            // Items need to be loaded by an async method

            // Call async provided method to get raw items
            const settings = {
              offset: this.offset,
              perPage: this.perPage,
              filters: this.filters
            }
            if(this.search) {
              settings.search = this.search
            }
            const rawItems = await this.loadItems(settings)

            if(rawItems) {
              if(rawItems.length < this.perPage) {
                this.nothingToLoad = true
              }
              if(this.offset === 0) {
                this.itemsToDisplay = rawItems
              }
              else {
                this.itemsToDisplay = this.itemsToDisplay.concat(rawItems)
              }
            }
            else {
              this.itemsToDisplay = []
              this.nothingToLoad = true
            }
          }
          else {
            // All items are provided in the prop
            let itemsToConsider = this.items
            if(this.search && this.searchProperties) {
              itemsToConsider = this.filteredItems
            }
            if(itemsToConsider.length > 0) {
              this.itemsToDisplay = itemsToConsider.slice(0, this.offset +  this.perPage)
              if(this.itemsToDisplay.length === itemsToConsider.length) {
                this.nothingToLoad = true
              }
            }
            else {
              this.itemsToDisplay = []
              this.nothingToLoad = true
            }
          }
          // Waiting for the whole rendering to be done so anything depending of the end does wait for it
          this.$forceNextTick(() => {
            this.internalLoading = false
          })
        }
      },
      resetLoadStates () {
        this.offset = 0
        this.itemsToDisplay = []
        this.nothingToLoad = false
      },
      reload () {
        this.resetLoadStates()
        this._loadItems()
      },
      clickItem (item) {
        this.$emit('clickedItem', item)
      },
      updateSearch: _.debounce(function (value) {
        if(value) {
          this.search = value
        }
        else {
          this.search = ''
        }
        if(!this.loadItems) {
          // Only in case of internal loading then we filter ourselves
          if(this.search && this.searchProperties) {
            // Force string cast since value coms from proxy object since declared in data
            let noarmalizedSearch = ''+this.search
            if(typeof "".normalize === "function") {
              noarmalizedSearch = noarmalizedSearch.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
            }
            this.filteredItems = this.items.filter((i) => {
              return this.searchProperties.reduce((prev, curr) => {
                if(i.hasOwnProperty(curr)) {
                  let itemValue = i[curr]
                  if(typeof itemValue !== 'string') {
                    itemValue = JSON.stringify(itemValue)
                  }
                  if(typeof "".normalize === "function") {
                    itemValue = itemValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                  }
                  return prev || itemValue.toLowerCase().indexOf(noarmalizedSearch.toLowerCase()) > -1
                }
                else {
                  return prev
                }
              }, false)
            })
          }
          else {
            this.filteredItems = []
          }
        }
        this.resetLoadStates()
        this._loadItems()
      }, 300),
      clearSearch () {
        this.updateSearch(null)
      },
      onIntersect: async function (isIntersecting, entries, observer) {
        // Check the state of the last intersection change
        const hasIntersection = entries[entries.length-1].isIntersecting
        if(hasIntersection && !this.nothingToLoad && !this.internalLoading) {
          await this._loadItems()
          // Next page to load will start at new offset
          this.offset += this.perPage
        }
      }
    }
};
</script>

<style lang="scss" scoped>
.listContent {
  flex-basis: 0;
  overflow: auto;
  padding: 0 12px;
  margin: 0;
}
.listPlaceholder {
  align-self: stretch;

  .emptyImg {
    margin: 0 auto;
  }
}
.interactor {
  height: 1px;
}
.max-width {
  max-width: 100%;
}
</style>