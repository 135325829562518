import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import vue3GoogleLogin from 'vue3-google-login'

const app = createApp(App)

import { createPinia } from 'pinia'
import { createORM } from 'pinia-orm'

const pinia = createPinia().use(createORM())
app.use(pinia)

import { VueMasonryPlugin } from "vue-masonry";
app.use(VueMasonryPlugin);

app.use(vue3GoogleLogin, {
  clientId: import.meta.env.VITE_GAUTH_CLIENT_ID
})

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
app.component('VueDatePicker', VueDatePicker);

import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
app.use(Toast, {
  maxToasts: 10,
  newestOnTop: true,
  timeout: 3000,
  position: 'top-center'
})

import VueForceNextTick from 'vue-force-next-tick'
app.use(VueForceNextTick)

// Warn: vuetify overrides the v-tooltip directive
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
app.use(FloatingVue)

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
app.component('EasyDataTable', Vue3EasyDataTable);

import interceptorsSetup from "@/helpers/interceptors"
interceptorsSetup(router)
import "@/helpers/axios"

import { registerSW } from 'virtual:pwa-register'
const updateSW = registerSW({
  onNeedRefresh() {
    const event = new CustomEvent('swUpdated', {
      detail: {
        callback: updateSW
      }
    })
    document.dispatchEvent(event)
  },
  onOfflineReady() { },
  onRegistered(r) {
    r && setInterval(() => {
      r.update()
    }, 60 * 60 * 1000)
  }
})

app.use(vuetify)

app.use(router)
app.mount('#app')