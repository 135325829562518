// User Model
import { Model, Repository } from 'pinia-orm'
import axios from 'axios'

class Globalconfig extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'globalconfig'

  static primaryKey = 'name'

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      name: this.attr('').notNullable(),
      value: this.attr('')
    }
  }
}

class GlobalconfigRepo extends Repository {
  use = Globalconfig

  async $initForecast() {
    return new Promise(async (resolve, reject) => {
      try {
        const w = this.find('weather')
        if(!w) {
          const weather = await axios.get('/weather/get.php')
          resolve(this.save({
            name: 'weather',
            value: weather.data
          }))
        }
        else {
          resolve({
            name: 'weahter',
            value: w
          })
        }
      }
      catch (e) {
        reject()
      }
    })
  }
}

export {
  Globalconfig,
  GlobalconfigRepo
}