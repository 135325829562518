<template>
  <v-btn
    v-tooltip:bottom="description"
    variant="flat"
    rounded
    density="comfortable"
    color="blue-lighten-3"
    size="large"
    class="mainContainer px-4"
  >
    <template #prepend>
      <v-img
        :src="'/weather/'+icon+'.svg'"
        width="32"
        height="32"
      />
    </template>
    {{ temperature+"°C" }}
  </v-btn>
</template>

<script>
export default {
  name: "Weather",
  components: {},
  props: {
    icon: {
      type: String,
      default: 'dots-circle',
    },
    temperature: {
      type: Number,
      default: 0,
    },
    description: {
      type: String,
      default: '',
    }
  }
}
</script>

<style lang="scss" scoped>
.mainContainer {
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, .2);
}

</style>