<template>
  <v-card :id="componentId">
    <v-img
      :src="recipe.image ? recipe.image : defaultRecipeImage"
      height="200px"
      class="titleImage"
      cover
    >
      <v-container class="pa-0 pb-3 h-100 d-flex flex-column justify-space-between">
        <v-row dense class="topBar" justify="space-between">
          <v-col cols="auto" class="pl-2">
            <recipe-share :recipe="recipe" :small="true" color="white" :container-id="componentId" class="shareBtn" />
          </v-col>
          <v-col v-if="canLockDisplay" cols="auto">
            <v-btn variant="text" class="lockButton" @click="toggleLock">
              <v-icon class="pr-2" size="large">
                {{ lockedDisplay ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off-outline' }}
              </v-icon>
              {{ lockedDisplay ? 'Ecran toujours allumé' : 'Empêcher la veille ?' }}
            </v-btn>
          </v-col>
          <v-col cols="auto" class="pr-2">
            <v-btn
              icon
              size="x-small"
              title="Fermer"
              class="closeBtn"
              @click="close"
            >
              <v-icon
                size="24"
                color="white"
              >
                mdi-close-circle
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="cardTitle upperFirstLetter pb-0 text-white">
            {{ recipe.name }}
            <v-btn
              v-if="recipe.source"
              icon
              size="small"
              :href="recipe.source"
              target="_blank"
              title="Ouvrir la recette d'origine"
              variant="text"
            >
              <v-icon
                size="small"
              >
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          justify="center"
          dense
          class="contentSlider pb-1"
        >
          <v-col
            cols="auto"
          >
            <v-btn
              icon
              size="x-small"
              variant="text"
              density="compact"
              class="ma-2"
              @click="carousel = 0"
            >
              <v-icon
                size="large"
                color="white"
              >
                {{ carousel === 0 ? 'mdi-circle' : 'mdi-circle-outline' }}
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
          >
            <v-btn
              icon
              size="x-small"
              variant="text"
              density="compact"
              class="ma-2"
              @click="carousel = 1"
            >
              <v-icon
                size="large"
                color="white"
              >
                {{ carousel === 1 ? 'mdi-circle' : 'mdi-circle-outline' }}
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
          >
            <v-btn
              icon
              size="x-small"
              variant="text"
              density="compact"
              class="ma-2"
              @click="carousel = 2"
            >
              <v-icon
                size="large"
                color="white"
              >
                {{ carousel === 2 ? 'mdi-circle' : 'mdi-circle-outline' }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-img>

    <v-card-text
      class="cardText pa-2 fill-height"
    >
      <v-carousel
        v-model="carousel"
        :show-arrows="false"
        hide-delimiters
        height="100%"
      >
        <v-carousel-item
          eager
          content-class="carouselItem"
        >
          <v-row
            justify="space-around"
            class="mx-0 my-5"
          >
            <v-col
              cols="auto"
              class="pa-0"
            >
              <picto-info
                icon="mdi-clock-outline"
                title="Préparation"
                :value="formatTime(recipe.preparation_time)"
                :mini="true"
              />
            </v-col>
            <v-col
              cols="auto"
              class="pa-0"
            >
              <picto-info
                icon="mdi-pot-steam-outline"
                title="Cuisson"
                :value="formatTime(recipe.baking_time)"
                :mini="true"
              />
            </v-col>
            <v-col
              cols="auto"
              class="pa-0"
            >
              <picto-info
                icon="mdi-account-group"
                title="Convives"
                :value="''+recipe.nb_people"
                :mini="true"
              />
            </v-col>
            <v-col
              v-if="recipe.difficulty"
              cols="auto"
              class="pa-0"
            >
              <picto-info
                icon="mdi-chef-hat"
                title="Difficulté"
                :value="difficultyLevels.filter(d => d.value === recipe.difficulty)[0].text"
                :mini="true"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="recipe.dish_type"
            class="mx-0 px-3 text-body-2"
          >
            <v-col cols="6">
              <v-icon
                color="primary"
              >
                mdi-cupcake
              </v-icon>
              Type de plat
            </v-col>
            <v-col>
              {{ dishTypes.filter(m => m.value === recipe.dish_type)[0].text }}
            </v-col>
          </v-row>
          <v-row
            v-if="recipe.cuisine_type"
            class="mx-0 px-3 text-body-2"
          >
            <v-col cols="6">
              <v-icon
                color="primary"
              >
                mdi-earth
              </v-icon>
              Type de cuisine
            </v-col>
            <v-col>
              {{ cuisineTypes.filter(m => m.value === recipe.cuisine_type)[0].text }}
            </v-col>
          </v-row>
          <v-row
            v-if="recipe.baking_type"
            class="mx-0 px-3 text-body-2"
          >
            <v-col cols="6">
              <v-icon
                color="primary"
              >
                mdi-pot-mix-outline
              </v-icon>
              Type de cuisson
            </v-col>
            <v-col>
              {{ bakingTypes.filter(m => m.value === recipe.baking_type)[0].text }}
            </v-col>
          </v-row>
          <v-row
            justify="center"
            dense
            class="mx-0"
          >
            <v-col cols="auto" class="pa-0">
              <star-vote :value="recipe.score" :min="1" :max="4" @clicked-star="setScore" />
            </v-col>
          </v-row>
          <v-row
            v-if="recipe.comment"
            class="mx-0"
          >
            <v-col>
              <div class="blockquote pa-2">
                <v-icon color="primary" size="small" class="quoteIcon">
                  mdi-format-quote-open
                </v-icon>
                {{ recipe.comment }}
                <v-icon color="primary" size="small" class="quoteIcon">
                  mdi-format-quote-close
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-carousel-item>
        <v-carousel-item
          eager
          content-class="carouselItem"
        >
          <v-row
            dense
            class="mx-0 mb-3"
          >
            <v-col
              class="text-h5 text-center"
            >
              Ingrédients
            </v-col>
          </v-row>
          <v-row
            dense
            class="mx-0"
          >
            <v-col>
              <v-row
                v-for="ingredient in recipe.ingredients"
                :key="ingredient.ingredient_id"
                dense
              >
                <v-col
                  cols="4"
                  class="text-center"
                  align-self="center"
                >
                  {{ ingredient.amount ? ingredient.amount : '' }}
                  {{ ingredient.unit_shorthand }}
                </v-col>
                <v-col cols="auto" align-self="center">
                  <v-img
                    v-if="ingredient.ingredient.image_url"
                    :src="ingredient.ingredient.image_url"
                    width="30"
                    height="30"
                    cover
                  />
                  <div
                    v-if="!ingredient.ingredient.image_url"
                    class="noIngredientImage"
                  />
                </v-col>
                <v-col align-self="center">
                  {{ ingredient.ingredient ? ingredient.ingredient.name : '' }}
                  <v-icon
                    v-if="ingredient.original"
                    v-tooltip="{text: ingredient.original, openOnClick: true, location: 'top'}"
                    color="secondary"
                  >
                    mdi-information-outline
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-carousel-item>
        <v-carousel-item
          eager
          content-class="carouselItem"
        >
          <v-row
            dense
            class="mx-0 mb-3"
          >
            <v-col
              class="text-h5 text-center"
            >
              Instructions
            </v-col>
          </v-row>
          <v-row
            dense
            class="mx-0"
          >
            <v-col>
              <v-row
                v-for="(instruction, index) in recipe.instructions"
                :key="index"
                class="mx-0"
              >
                <v-col
                  cols="auto"
                  class="pl-0"
                >
                  <v-chip
                    color="primary"
                    variant="outlined"
                    class="instructionIndex"
                  >
                    {{ index+1 }}
                  </v-chip>
                </v-col>
                <v-col
                  align-self="center"
                  class="pl-0 upperFirstLetter text-body-2"
                >
                  {{ instruction }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-card-text>
  </v-card>
</template>

<script>
import { useRepo } from 'pinia-orm'
import { Recipe, RecipeRepo } from '@/models/Recipe'
import { Ingredient } from '@/models/Ingredient'
import PictoInfo from '@/components/PictoInfo'
import StarVote from '@/components/StarVote'
import RecipeShare from '@/components/RecipeShare'
import moment from 'moment'
import shared from '@/shared'
import recipeImg from '@/assets/recipe.png'

export default {
  name: 'RecipeEdition',
  components: {
    PictoInfo,
    StarVote,
    RecipeShare
  },
  props: {
    recipe: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'Recette'
    }
  },
  emits: ['close', 'edit', 'updateScore'],
  data: () => ({
    uid: shared.getUid(),
    carousel: 0,
    defaultRecipeImage: recipeImg,

    wakeLock: null,
    lockedDisplay: false
  }),
  computed: {
    canLockDisplay () {
      return 'wakeLock' in navigator
    },
    componentId () {
      return 'recipeDetailMobile-'+this.uid
    },
    dishTypes () {
      return Recipe.dishTypes()
    },
    cuisineTypes () {
      return Recipe.cuisineTypes()
    },
    bakingTypes () {
      return Recipe.bakingTypes()
    },
    ingredientUnits () {
      return Ingredient.units()
    },
    difficultyLevels () {
      return Recipe.difficultyLevels()
    },
  },
  created () {
  },
  methods: {
    async toggleLock () {
      if(this.lockedDisplay) {
        if(this.wakeLock) {
          this.wakeLock.release()
        }
        else {

        }
        this.lockedDisplay = false
      }
      else {
        try {
          this.wakeLock = await navigator.wakeLock.request('screen');
          this.lockedDisplay = true
        } catch (err) {

        }
      }
    },
    formatTime (minutes) {
      const d = moment.duration(minutes, 'minutes')
      let time = ''
      time +=  d.hours() === 0 ? '' : d.hours() + ' h '
      time += d.minutes() === 0 ? '' : (d.minutes() < 10 ? '0' + d.minutes() : d.minutes()) + ' m'
      return time || '-'
    },
    close () {
      if(this.wakeLock) {
        this.wakeLock.release()
        this.lockedDisplay = false
      }
      this.$emit('close')
    },
    edit () {
      this.$emit('edit')
    },
    setScore (score) {
      useRepo(RecipeRepo).$update(this.recipe.id, {
        score: score
      })
      // We are working on a copy of the recipe: update local copied data
      this.$emit('updateScore', score)
    }
  }
}
</script>

<style lang="scss" scoped>
.titleImage {
  position: relative;

  .contentSlider {
    background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
  }

  .topBar {

    .shareBtn {
      background-color: rgba(0, 0, 0, .5);
      border-radius: 50%;
    }

    .lockButton {
      background-color: rgba(255, 255, 255, .5);
    }

    .closeBtn {
      background-color: rgba(0, 0, 0, .5);
      width: 28px;
      height: 28px;
    }
  }
}
.cardTitle {
  text-shadow: 0px 0px 2px black;
  text-align: center;
  display: block;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 500;
  word-break: break-word;
  max-height: 120px;
  overflow-y: hidden;
}
.cardText {
  margin-top: -10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 1;
  background-color: white;

  :deep(.carouselItem) {
    overflow-y: auto;
  }
}
.image {
  border-radius: 10px;
  background-color: rgb(var(--v-theme-primary));
}
.miniPicto {
  transform: scale(.7);
}

.quoteIcon {
  vertical-align: top;
}

.instructionIndex {
  min-width: 40px;
  justify-content: center;
}

.noIngredientImage {
  height: 100%;
  min-height: 30px;
  width: 30px;
  padding-top: 100%;
  position: relative;

  &:after {
    content: "";
    border: 2px dashed rgb(var(--v-theme-background-darken-1));
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>