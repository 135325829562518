<template>
  <v-dialog
    v-model="computedShow"
    :fullscreen="$vuetify.display.xs"
    width="600"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 bg-primary text-white d-flex align-center">
        Item à stocker
        <v-spacer v-if="$vuetify.display.xs" />
        <span v-if="$vuetify.display.xs">
          <v-btn
            icon
            :disabled="validating"
            variant="text"
            size="small"
            @click="cancel"
          >
            <v-icon size="large">
              mdi-close
            </v-icon>
          </v-btn>
          <v-btn
            icon
            :loading="validating"
            variant="text"
            size="small"
            @click="handleValidation"
          >
            <v-icon size="large">
              mdi-check-circle
            </v-icon>
          </v-btn>
        </span>
      </v-card-title>
      <v-card-text class="pt-8 scrollbars">
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="name"
              name="name"
              label="Nom"
              variant="outlined"
              hide-details
              class="mb-2"
              clearable
              @click:clear="clear"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="type"
              :items="storedItemTypes"
              label="Type"
              item-title="text"
              item-value="value"
              variant="outlined"
              hide-details
              clearable
            />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="6" md="4">
            Nombre de parts :
          </v-col>
          <v-col cols="6" md="3" class="text-center">
            <int-stepper
              v-model="quantity_parts"
              :min="1"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" md="3">
            Quantité :
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              v-model="quantity_volume"
              type="number"
              variant="outlined"
              hide-details
              class="mr-2"
              clearable
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-select
              v-model="quantity_unit"
              :items="storedItemUnits"
              item-title="text"
              item-value="value"
              variant="outlined"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            Date de stockage
            <VueDatePicker
              v-model="storage_date"
              :enable-time-picker="false"
              select-text="OK"
              cancel-text="ANNULER"
              locale="fr"
              :teleport="true"
              position="left"
              allow-prevent-default
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-row dense>
              <v-col>
                Date d'expiration
                <VueDatePicker
                  v-model="expiration_date"
                  :enable-time-picker="false"
                  select-text="OK"
                  cancel-text="ANNULER"
                  locale="fr"
                  :teleport="true"
                  position="left"
                  :state="invalidExpirationDate ? false : null"
                  allow-prevent-default
                />
              </v-col>
              <v-col cols="auto" align-self="end" class="pb-2">
                <VDropdown
                  placement="bottom"
                  parent-id="app"
                >
                  <v-btn
                    icon
                    color="grey"
                    size="x-small"
                  >
                    <v-icon>
                      mdi-help
                    </v-icon>
                  </v-btn>
                  <template #popper>
                    <table class="tips">
                      <tbody>
                        <tr>
                          <th>Idéal pour...</th>
                          <th>Conservation</th>
                          <th>Conseils</th>
                        </tr>
                        <tr>
                          <td>abats</td>
                          <td>4-5 mois</td>
                          <td>Dégraisser au maximum</td>
                        </tr>
                        <tr>
                          <td>volaille</td>
                          <td>6 mois</td>
                          <td>Découper avant congélation</td>
                        </tr>
                        <tr>
                          <td>légumes</td>
                          <td>8-10 mois</td>
                          <td>Blanchir avant, découper dans l'eau bouillante</td>
                        </tr>
                        <tr>
                          <td>poissons</td>
                          <td>4-5 mois</td>
                          <td>Congeler extrêmement frais</td>
                        </tr>
                        <tr>
                          <td>plats cuisinés</td>
                          <td>3-6 mois</td>
                          <td>Saler, assaisonner après décongélation</td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                </VDropdown>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense justify="center">
          <v-col cols="auto">
            Etage :
            <int-stepper
              v-model="stack"
              :min="1"
              :max="maxStack"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="$vuetify.display.smAndUp">
        <v-spacer />
        <v-btn
          variant="text"
          :disabled="validating"
          @click="cancel"
        >
          Annuler
        </v-btn>
        <v-btn
          :disabled="!canSave"
          :loading="validating"
          color="primary"
          @click="handleValidation"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useRepo } from "pinia-orm"
import { StoredItem, StoredItemRepo } from "@/models/StoredItem"
import IntStepper from "@/components/IntStepper"
import moment from 'moment'

export default {
  name: "StoredItemEdition",
  components: {
    IntStepper
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    new: {
      type: Boolean,
      default: false
    },
    storedItemId: {
      type: Number,
      default: 0
    },
    storageId: {
      type: Number,
      default: 0
    },
    maxStack: {
      type: Number,
      default: 1
    },
    defaultStack: {
      type: Number,
      default: 0
    },
  },
  emits: ['close'],
  data: () => ({
    validating: false,
    name: '',
    type: undefined,
    storage_date: null,
    expiration_date: null,
    quantity_parts: 1,
    quantity_volume: 1,
    quantity_unit: 'nb',
    stack: 1
  }),
  computed: {
    storedItem() {
      return useRepo(StoredItem).find(this.storedItemId)
    },
    computedShow: {
      get() {
        return this.show
      },
      set(v) {
        if(!v) {
          this.$emit('close')
        }
      }
    },
    storedItemTypes() {
      return StoredItem.allTypes()
    },
    storedItemUnits() {
      return StoredItem.units()
    },
    canSave() {
      return !!this.name && !this.invalidExpirationDate
    },
    invalidExpirationDate () {
      return this.expiration_date && this.storage_date && moment(this.expiration_date).isBefore(this.storage_date)
    }
  },
  created() {
    if(!this.new && this.storedItem) {
      this.name = this.storedItem.name
      this.type = this.storedItem.type
      this.storage_date = this.storedItem.storage_date
      this.expiration_date = this.storedItem.expiration_date
      this.quantity_parts = this.storedItem.quantity_parts
      this.quantity_volume = this.storedItem.quantity_volume
      this.quantity_unit = this.storedItem.quantity_unit
      this.stack = this.storedItem.stack+1 // TO SHIFT DISPLAY
    }
  },
  mounted() {
    if(this.new) {
      this.stack = this.defaultStack // NO SHIFT NEEDED
      this.storage_date = moment().format('Y-MM-DD')
    }
  },
  methods: {
    clear () {
      this.name = ''
    },
    handleValidation: async function() {
      if(this.new) {
        await this.create()
      }
      else {
        await this.update()
      }
    },
    create: async function() {
      this.validating = true
      const data = {
        name: this.name,
        type: this.type,
        storage_date: this.storage_date,
        expiration_date: this.expiration_date,
        quantity_parts: this.quantity_parts,
        quantity_volume: this.quantity_volume,
        quantity_unit: this.quantity_unit,
        stack: this.stack-1,
        storage_id: this.storageId
      }
      await useRepo(StoredItemRepo).$create(data)
      this.validating = false
      this.$emit('close')
    },
    update: async function() {
      this.validating = true
      const data = {
        name: this.name,
        type: this.type,
        storage_date: this.storage_date,
        expiration_date: this.expiration_date,
        quantity_parts: this.quantity_parts,
        quantity_volume: this.quantity_volume,
        quantity_unit: this.quantity_unit,
        stack: this.stack-1,
      }
      await useRepo(StoredItemRepo).$update(this.storedItemId, data)
      this.validating = false
      this.$emit('close')
    },
    cancel() {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
.tips {
  text-align: left;
  color: rgb(var(--v-theme-primary));
  border-spacing: 15px 0;
}
</style>