<template>
  <v-container>
    <v-row :class="switchToResult ? 'hidden' : ''" justify="center">
      <v-col cols="auto" class="text-center">
        <input
          ref="fileInput"
          type="file"
          accept="image/*"
          capture="environment"
          class="fileInput"
          @change="filechangeWithQRScanner"
        />
        <v-img
          src="@/assets/camerafocus.svg"
          width="300"
          class="mb-8"
        />
        <v-btn
          @click="triggerFileUpload"
        >
          Prendre une photo
        </v-btn>
      </v-col>
    </v-row>
    <v-row :class="switchToResult ? '' : 'hidden'" dense>
      <v-col cols="12" sm="auto" class="text-center">
        <v-btn variant="text" size="small" @click="restart">
          <v-icon class="mr-2" size="small">
            mdi-backspace-outline
          </v-icon>
          Recommencer
        </v-btn>
      </v-col>
      <v-spacer v-if="$vuetify.display.smAndUp" />
      <v-col v-if="!loadingScan && !loadingTreatment" cols="12" sm="auto" class="text-center">
        <v-btn
          :disabled="!canMoveMealsUp"
          class="ml-2 moveLinesBtn"
          variant="outlined"
          size="small"
          @click="moveMealsUp"
        >
          <v-icon class="mr-2" size="small">
            mdi-chevron-triple-up
          </v-icon>
          Monter
        </v-btn>
        <v-btn
          :disabled="!canMoveMealsDown"
          class="ml-2 moveLinesBtn"
          variant="outlined"
          size="small"
          @click="moveMealsDown"
        >
          <v-icon class="mr-2" size="small">
            mdi-chevron-triple-down
          </v-icon>
          Descendre
        </v-btn>
      </v-col>
    </v-row>
    <v-row :class="switchToResult ? '' : 'hidden'">
      <v-col v-show="loadingScan || loadingTreatment" cols="12">
        <canvas ref="canvas" class="canvas" />
      </v-col>
      <v-col v-show="!loadingScan && !loadingTreatment" cols="12">
        <v-row v-for="(day, index) in dayNames" :key="day.key" dense class="mb-3">
          <v-col class="lunch text-center">
            <template v-if="scannedRows.filter(r => r.day === index).length && scannedRows.filter(r => r.day === index)[0].lunch.length">
              <div
                v-for="code in scannedRows.filter(r => r.day === index)[0].lunch"
                :key="code.data"
              >
                <recipe-card :scanned-code="code.data" :small="$vuetify.display.xs" />
              </div>
            </template>
            <template v-else>
              <span class="emptyDay">
                Rien à préparer
              </span>
            </template>
          </v-col>
          <v-col cols="auto" class="day text-center">
            {{ day.value }}
            <br />
            <v-btn
              v-if="constraints[index].canMoveUp"
              variant="outlined"
              class="px-2 mt-2"
              size="small"
              @click="moveRowUp(index)"
            >
              <v-icon>
                mdi-chevron-triple-up
              </v-icon>
            </v-btn>
            <br />
            <v-btn
              v-if="constraints[index].canMoveDown"
              variant="outlined"
              class="px-2 mt-2"
              size="small"
              @click="moveRowDown(index)"
            >
              <v-icon>
                mdi-chevron-triple-down
              </v-icon>
            </v-btn>
            <br />
            <v-icon
              v-if="constraints[index].hasMeal"
              v-tooltip="'Des repas seront remplacés : '+constraints[index].mealDesc"
              color="warning"
              size="x-small"
            >
              mdi-alert-circle
            </v-icon>
          </v-col>
          <v-col class="diner text-center">
            <template v-if="scannedRows.filter(r => r.day === index).length && scannedRows.filter(r => r.day === index)[0].diner.length">
              <div
                v-for="code in scannedRows.filter(r => r.day === index)[0].diner"
                :key="code.data"
              >
                <recipe-card :scanned-code="code.data" :small="$vuetify.display.xs" />
              </div>
            </template>
            <template v-else>
              <span class="emptyDay">
                Rien à préparer
              </span>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="switchToResult && !loadingScan && !loadingTreatment" dense>
      <v-col cols="12" class="text-center">
        <v-btn
          :loading="loadingValidation"
          color="primary"
          @click="validateScan"
        >
          Valider
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import QrScanner from 'qr-scanner'
import RecipeCard from '@/components/RecipeCard'
import moment from 'moment'
import { useRepo } from 'pinia-orm'
import { Meal } from '@/models/Meal'
import { Recipe } from '@/models/Recipe'
import { MealRepo } from '@/models/Meal'
import { CompositionRepo } from '@/models/Composition'

export default {
  name: "RecipeImageScanner",
  components: {
    RecipeCard
  },
  emits: ['done'],
  data: () => ({
    loadingScan: false,
    loadingTreatment: false,
    switchToResult: false,
    previewImg: "",
    dayNames: [
      { key: 'monday', value: 'L', offset: 0 },
      { key: 'tuesday', value: 'M', offset: 1 },
      { key: 'wednesday', value: 'M', offset: 2 },
      { key: 'thursday', value: 'J', offset: 3 },
      { key: 'friday', value: 'V', offset: 4 },
      { key: 'saturday', value: 'S', offset: 5 },
      { key: 'sunday', value: 'D', offset: 6 }],
    scannedRows: [],
    constraints: new Array(7).fill({}),
    loadingValidation: false
  }),
  computed: {
    canMoveMealsUp() {
      let minDay = 7
      this.scannedRows.forEach((row) => {
        if (row.day < minDay) {
          minDay = row.day
        }
      })
      return this.scannedRows.length > 0 && minDay > 0
    },
    canMoveMealsDown() {
      let minDay = -1
      this.scannedRows.forEach((row) => {
        if (row.day > minDay) {
          minDay = row.day
        }
      })
      return this.scannedRows.length > 0 && minDay > -1 && minDay < 6
    },
    firstDay() {
      let firstDay = new Date()
      const localStart = localStorage.getItem('calendar-start')
      if (localStart) {
        firstDay = moment.utc(localStart, moment.ISO_8601)
      }
      return firstDay
    },
    currentWeekMealsId() {
      const meals = useRepo(Meal).where('date', (value) => moment.utc(value).isSameOrAfter(this.firstDay, 'week')).get()
      return meals.map((m) => m.id)
    }
  },
  async mounted() { },
  methods: {
    triggerFileUpload() {
      this.$refs.fileInput.click()
    },
    restart() {
      this.$refs.fileInput.value = null
      const canvas = this.$refs.canvas
      const context = canvas.getContext('2d')
      context.clearRect(0, 0, canvas.width, canvas.height)
      this.switchToResult = false
    },
    convert(myFile) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        if (fileReader && myFile) {
          fileReader.onload = () => {
            resolve(fileReader.result)
          }
          fileReader.onerror = (error) => {
            reject(error)
          }
          fileReader.readAsDataURL(myFile)
        } else {
          reject("No file provided")
        }
      })
    },
    processImage(img) {
      return new Promise((resolve, reject) => {
        const tmpImg = new Image()
        tmpImg.onload = function () {
          resolve({
            width: this.width,
            height: this.height,
            element: tmpImg,
          })
        }
        tmpImg.addEventListener("error", (e) => {
          reject()
        })
        tmpImg.src = img
      })
    },
    updateConstraints() {
      // beware: "fill" fills by ref for objects...
      const newConstraints = new Array(7).fill().map(i => ({
        canMoveUp: false,
        canMoveDown: false,
        row: null
      }))
      // ScannedRows are sorted by day index
      this.scannedRows.forEach((row) => {
        newConstraints[row.day].row = row
        if (this.firstDay && this.firstDay.isValid()) {
          const meals = useRepo(Meal).where('date', (value) => {
            const mDate = moment.utc(value)
            return mDate.isSame(this.firstDay, 'week') && mDate.isoWeekday() === (row.day + 1)
          }).get()
          newConstraints[row.day].hasMeal = meals.length > 0
          newConstraints[row.day].mealDesc = meals.length > 0 ? meals.map((m) => m.name).join(', ') : ''
        }
        newConstraints[row.day].canMoveUp = row.day > 0 &&
          (row.lunch.length > 0 || row.diner.length > 0) &&
          (!newConstraints[row.day - 1].row ||
            (newConstraints[row.day - 1].row.lunch.length === 0 && newConstraints[row.day - 1].row.diner.length === 0))
      })
      // Second pass to get down move since next row was not stored
      this.scannedRows.forEach((row) => {
        newConstraints[row.day].canMoveDown = row.day < 6 &&
          (row.lunch.length > 0 || row.diner.length > 0) &&
          (!newConstraints[row.day + 1].row ||
            (newConstraints[row.day + 1].row.lunch.length === 0 && newConstraints[row.day + 1].row.diner.length === 0))
      })
      this.constraints = newConstraints
    },
    async filechangeWithQRScanner() {
      this.loadingScan = true
      this.scannedRows = []

      const f = this.$refs.fileInput
      if (f.files && f.files.length) {
        this.switchToResult = true

        let img = await this.convert(f.files[0])
        this.previewImg = img
        const imgInfo = await this.processImage(img)

        const canvas = this.$refs.canvas
        canvas.width = imgInfo.width
        canvas.height = imgInfo.height
        const context = canvas.getContext("2d")
        context.drawImage(imgInfo.element, 0, 0, imgInfo.width, imgInfo.height)

        let stopLoop = false
        let foundResult = null
        let allResults = []
        const qrEngine = await QrScanner.createQrEngine()
        do {
          try {
            foundResult = await QrScanner.scanImage(canvas, {
              qrEngine: qrEngine,
              canvas: canvas,
              returnDetailedScanResult: true
            })

            // console.log("found rescanned barcode", foundResult)
            if (allResults.length > 0 && foundResult.data === allResults[0])
              stopLoop = true
            allResults.push(foundResult)

            // Mask the code with an overlay at its coordinates
            context.fillStyle = '#DA8D02'
            context.beginPath()
            context.moveTo(foundResult.cornerPoints[0].x, foundResult.cornerPoints[0].y)
            context.lineTo(foundResult.cornerPoints[1].x, foundResult.cornerPoints[1].y)
            context.lineTo(foundResult.cornerPoints[2].x, foundResult.cornerPoints[2].y)
            context.lineTo(foundResult.cornerPoints[3].x, foundResult.cornerPoints[3].y)
            context.closePath()
            context.fill()

          } catch (e) {
            // console.error(e)
            stopLoop = true
          }
        }
        while (!!foundResult && !stopLoop)

        this.loadingScan = false
        this.loadingTreatment = true

        if (allResults.length > 0) {
          // Get size of a code by assuming they are squared
          const firstCode = allResults[0]
          // console.log(firstCode)

          // Check first result orientation to get overall image orientation
          // QRCode : first point is always top left and working clockwise
          const minX = firstCode.cornerPoints.map(({ x }) => x).sort()[0]
          const maxX = firstCode.cornerPoints.map(({ x }) => x).sort().reverse()[0]
          const minY = firstCode.cornerPoints.map(({ y }) => y).sort()[0]
          //console.log(minX, minY)

          const codeSize = maxX - minX
          // console.log(codeSize)

          let xAxis = 'x'
          let yAxis = 'x'
          let direction = 1
          let halfWidth = imgInfo.width / 2
          let fullHeight = imgInfo.height
          // Compare to first point (top left)
          // Adding a half codeSize to handle a slight rotation
          if (Math.abs(minX - firstCode.cornerPoints[0].x) < codeSize / 2) {
            if (Math.abs(minY - firstCode.cornerPoints[0].y) < codeSize / 2) {
              // orientation = 'vertical'
              xAxis = 'x'
              yAxis = 'y'
              direction = 1
              halfWidth = imgInfo.width / 2
              fullHeight = imgInfo.height
            }
            else {
              // orientation = 'horizontal'
              xAxis = 'y'
              yAxis = 'x'
              direction = 1
              halfWidth = imgInfo.height / 2
              fullHeight = imgInfo.width
            }
          }
          else {
            if (Math.abs(minY - firstCode.cornerPoints[0].y) < codeSize / 2) {
              // orientation = 'horizontal-reverse'
              xAxis = 'y'
              yAxis = 'x'
              direction = -1
              halfWidth = imgInfo.width / 2
              fullHeight = imgInfo.height
            }
            else {
              // orientation = 'vertical-reverse'
              xAxis = 'x'
              yAxis = 'y'
              direction = -1
              halfWidth = imgInfo.height / 2
              fullHeight = imgInfo.width
            }
          }
          // console.log(xAxis, yAxis, direction)

          // Compare first vertical point of each found code to get rows
          const rows = []
          allResults.forEach((res) => {
            let found = false
            rows.forEach((row) => {
              if (Math.abs(row.codes[0].cornerPoints[0][yAxis] - res.cornerPoints[0][yAxis]) <= codeSize) {
                // Same row
                row.codes.push(res)
                found = true
              }
            })
            if (!found) {
              rows.push({
                codes: [res]
              })
            }
          })

          if (direction === -1) {
            // Inverse yAxis height
            rows.map((r) => {
              r.codes.map((c) => {
                c.cornerPoints[0][yAxis] = fullHeight - c.cornerPoints[0][yAxis]
              })
            })
          }

          // Sort from first line to last
          rows.sort((r1, r2) => {
            const r1First = r1.codes[0].cornerPoints[0][yAxis]
            const r2First = r2.codes[0].cornerPoints[0][yAxis]
            return r1First - r2First
          })
          // console.log(rows)
          if (rows.length < 7) {
            // We have gaps!!!
            // If we have > 3 rows, 2 must be consecutive and min gap can be used
            if (rows.length > 3) {
              // Get min distance between rows as reference
              let minRowGap = Number.MAX_SAFE_INTEGER
              let minY, maxY
              rows.forEach((row, index) => {
                if (index === 0) {
                  minY = row.codes[0].cornerPoints[0][yAxis]
                  return
                }
                const prevY = rows[index - 1].codes[0].cornerPoints[0][yAxis]
                const currY = row.codes[0].cornerPoints[0][yAxis]
                minRowGap = Math.min(minRowGap, currY - prevY)
                if (index === rows.length - 1) {
                  maxY = row.codes[0].cornerPoints[0][yAxis]
                }
              })

              const daysSpan = Math.floor((maxY - minY) / minRowGap)

              rows.forEach((row, index) => {
                if (index === 0) {
                  // Try to determine first row offset
                  if (daysSpan % 2 === 0) {
                    // missing either odd number of day => check distance to image edge
                    if (minY < (fullHeight - maxY)) {
                      // less distance to top edge
                      row.day = 0
                    }
                    else {
                      row.day = 1
                    }
                  }
                  else {
                    if (minY - (fullHeight - maxY) < codeSize) {
                      // Approximatively same distance to top and bottom => 1 day after and before
                      row.day = 1
                    }
                    else if (minY < (fullHeight - maxY)) {
                      // less distance to top edge
                      row.day = 0
                    }
                    else {
                      row.day = 2
                    }
                  }
                  return
                }
                const prevY = rows[index - 1].codes[0].cornerPoints[0][yAxis]
                const currY = row.codes[0].cornerPoints[0][yAxis]
                // Check if current gap is the min i.e. no missing day, modulo code size
                if ((currY - prevY) - minRowGap < codeSize) {
                  row.day = rows[index - 1].day + 1
                }
                else {
                  // Try to get number of empty days based on min gap
                  row.day = rows[index - 1].day + Math.floor((currY - prevY) / minRowGap)
                }
              })
            }
            else {
              // Try to guess empty rows based on the distance between rows compared to the qrcode size

              // TODO temp fallback
              rows.forEach((row, index) => {
                row.day = index
              })
            }
          }
          else {
            rows.forEach((row, index) => {
              row.day = index
            })
          }

          // Iterate on rows to get left and right depending on place compared to middle of the image
          rows.forEach((row) => {
            if (!row.lunch) row.lunch = []
            if (!row.diner) row.diner = []

            row.codes && row.codes.forEach((el) => {
              const lunch = direction === 1 ?
                el.cornerPoints[0][xAxis] < halfWidth :
                el.cornerPoints[0][xAxis] > halfWidth
              if (lunch) {
                row.lunch.push(el)
              }
              else {
                row.diner.push(el)
              }
            })
          })

          this.scannedRows = rows.sort((a, b) => a.day - b.day)
          this.updateConstraints()
        }
      }

      this.loadingTreatment = false
    },
    moveMealsUp() {
      this.scannedRows.forEach((row) => {
        row.day--
      })
      this.updateConstraints()
    },
    moveMealsDown() {
      this.scannedRows.forEach((row) => {
        row.day++
      })
      this.updateConstraints()
    },
    moveRowUp(index) {
      const row = this.scannedRows.filter((row) => row.day === index)
      if (row.length > 0) {
        row[0].day--
        this.updateConstraints()
      }
    },
    moveRowDown(index) {
      const row = this.scannedRows.filter((row) => row.day === index)
      if (row.length > 0) {
        row[0].day++
        this.updateConstraints()
      }
    },
    _validateMeals: async function (itemList, type, date) {
      const meal = {
        type,
        date
      }
      let name = ''
      const ingList = []
      itemList.forEach((item) => {
        const recipeId = Recipe.getIdFromQRCode(item.data)
        if (recipeId) {
          if (!meal.recipes) {
            meal.recipes = []
          }
          meal.recipes.push({
            recipe_id: recipeId
          })
          name === '' ? name = useRepo(Recipe).find(recipeId).name : name += ' - ' + useRepo(Recipe).find(recipeId).name
        }
        else {
          const ingId = Ingredient.getIdFromQRCode(item.data)
          if (ingId) {
            ingList.push(ingId)
            name === '' ? name = useRepo(Ingredient).find(ingId).name : name += ' - ' + useRepo(Ingredient).find(ingId).name
          }
        }
      })

      // Then create the new meals
      const createdMeal = await useRepo(MealRepo).$create({
        ...meal,
        name
      })
      const newMealId = createdMeal.id
      for (const i of ingList) {
        await useRepo(CompositionRepo).$create({
          ingredient_id: i,
          meal_id: newMealId
        })
      }

      return newMealId
    },
    validateScan: async function () {
      this.loadingValidation = true
      // First delete any previous meals if any: they are in store since we are updating current week
      if(this.currentWeekMealsId.length) {
        await useRepo(MealRepo).$bulkDelete(this.currentWeekMealsId)
      }
      for (const day of this.dayNames) {
        const dayRow = this.scannedRows.filter(r => r.day === day.offset)
        const date = moment.utc(this.firstDay).add(day.offset, 'days').format('Y-MM-DD')
        if(dayRow.length) {
          //console.log('treating day '+day.offset)
          if(dayRow[0].lunch.length) {
            //console.log('treating lunch '+dayRow[0].lunch.length)
            await this._validateMeals(dayRow[0].lunch, 'lunch', date)
          }
          if(dayRow[0].diner.length) {
            //console.log('treating diner '+dayRow[0].diner.length)
            await this._validateMeals(dayRow[0].diner, 'diner', date)
          }
        }
      }
      this.loadingValidation = false
      this.$emit('done')
    }
  },
}
</script>

<style lang="scss" scoped>
.player {
  width: 100%;
}
.canvas,
.preview {
  width: 100%;
}

.hidden {
  display: none !important;
}

.fileInput {
  opacity: 0;
}

.day {
  font-size: 2rem;
  line-height: 2rem;
}
.emptyDay {
  line-height: 2rem;
}

.moveLinesBtn {
  width: 45%;
}
</style>