<template>
  <VDropdown
    trigger="click"
    :auto-hide="true"
    :hide-on-target-click="false"
    class="shareInline"
    :container="containerSelector"
    :dispose-timeout="1000"
    :disabled="isOffline"
    @apply-hide="reset"
  >
    <template #popper>
      <v-card :id="cardId" width="350">
        <v-card-text>
          <v-row dense>
            <v-col>
              Partager à une autre communauté :
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto" align-self="center" class="pa-0">
              <v-btn
                v-tooltip:bottom="communityCodeHelp"
                icon
                size="x-small"
                variant="flat"
              >
                <v-icon
                  size="large"
                  color="grey-darken-1"
                >
                  mdi-help-circle
                </v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-text-field
                id="communityCode"
                v-model="communityCode"
                density="compact"
                single-line
                hide-details
                variant="outlined"
                placeholder="Code de la communauté"
                class="ma-0 pa-0"
              />
            </v-col>
            <v-col cols="auto" align-self="center">
              <v-btn
                icon
                :variant="!sharingSuccess && 'outlined'"
                :color="sharingSuccess ? 'success' : 'primary'"
                size="x-small"
                :loading="sharing"
                @click="communityShare"
              >
                <v-icon
                  size="large"
                >
                  mdi-check
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div class="choice">
            ou
          </div>
          <v-row v-if="canNativeShare" dense>
            <v-col class="text-center">
              <v-btn
                v-close-popper
                variant="text"
                @click="nativeShare"
              >
                <v-icon class="mr-2" size="small">
                  mdi-share-variant
                </v-icon>
                Partager
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="canCopy" dense>
            <v-col class="text-center">
              <v-btn
                v-close-popper
                variant="text"
                @click="copyShare"
              >
                <v-icon class="mr-2" size="small">
                  mdi-content-copy
                </v-icon>
                Copier la recette
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="!canNativeShare" dense>
            <v-col class="text-center">
              <v-btn
                v-close-popper
                variant="text"
                target="_blank"
                :href="mailtoShareURL"
              >
                <v-icon class="mr-2" size="small">
                  mdi-email-outline
                </v-icon>
                Partager par email
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <v-btn
      icon
      variant="text"
      :size="small ? 'x-small' : ''"
      :disabled="isOffline"
    >
      <v-icon
        :size="small ? 'medium' : ''"
        :color="iconColor"
      >
        mdi-share-variant
      </v-icon>
    </v-btn>
  </VDropdown>
</template>

<script>
import { useRepo } from "pinia-orm"
import { Globalconfig } from "@/models/Globalconfig"
import { useToast } from 'vue-toastification'
import shared from "../shared"

export default {
  name: 'RecipeShare',
  components: {
  },
  props: {
    recipe: {
      type: Object,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    },
    containerId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    sharing: false,
    sharingSuccess: false,
    communityCode: '',
    recipeText: '',
    uid: shared.getUid()
  }),
  computed: {
    isOffline() {
      return useRepo(Globalconfig).find('isOffline').value
    },
    cardId () {
      return 'card-'+this.uid
    },
    communityCodeHelp () {
      return {
        text: 'Demandez le code de sa communauté à la personne à qui vous voulez partager. Vous trouverez le votre dans la gestion de votre compte.',
        openOnClick: true,
        openOnHover: true,
        width: 300
      }
    },
    iconColor () {
      return this.color ? this.color : 'secondary'
    },
    containerSelector () {
      if(this.containerId) {
        return '#' + this.containerId
      }
      else {
        return '#app'
      }
    },
    canNativeShare() {
      return !!navigator.share
    },
    canCopy () {
      return !!navigator.clipboard
    },
    mailtoShareURL () {
      let url = 'mailto:?subject=' + this.recipe.name
      url += '&body=' + encodeURIComponent(this.recipeText)
      return url
    }
  },
  created () {
  },
  async mounted () {
    this.recipeText = await this.recipe.$toString()
  },
  methods: {
    reset () {
      this.communityCode = ''
      this.recipeText = ''
      this.sharing = false
      this.sharingSuccess = false
    },
    nativeShare () {
      if(navigator.share) {
        navigator.share({
          title: this.recipe.name,
          text: this.recipeText
        })
      }
    },
    copyShare () {
      if(navigator.clipboard) {
        navigator.clipboard.writeText(this.recipeText)
        this.open = false
      }
    },
    async communityShare () {
      if(this.communityCode) {
        this.sharing = true
        try {
          await this.recipe.$share(this.communityCode)
          this.sharingSuccess = true
        }
        catch(e) {
          this.sharingSuccess = false
          const toast = useToast()
          toast('Impossible de partager la recette avec cette communauté pour l\'instant. Vérifiez que vous avez bien saisi le code de partage.', {
            type: 'error'
          })
        }
        this.sharing = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.shareInline {
  display: inline-flex;
}

.choice {
  overflow: hidden;
  text-align: center;
  margin: 10px 25%;
  text-transform: uppercase;
}

.choice:before,
.choice:after {
  background-color: rgba(0, 0, 0, .6);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.choice:before {
  right: 0.5em;
  margin-left: -50%;
}

.choice:after {
  left: 0.5em;
  margin-right: -50%;
}
</style>